import React, { useState, useMemo, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty, getPluralPhrase, getDemoSelected } from '../../../Utils';

import {
  trackAnalysisStartSuccess,
  trackAnalysisStartError,
  trackAnalysisClickSeeProgress,
} from '../../../analytics';
import ProgressBar from '../../../components/progressBar';
import SellingPoint from '../../../components/sellingPoint';

import { URL } from '../../../constants';

import { analyseAll } from '../../../services/api/analyse';
import { useEventStore } from '../../../stores/event';

import FancyBtn from '../components/fancyBtn';
import { getAnalyseText } from '../components/getText';

const AnalyseStep = (props) => {
  const {
    peopleCount,
    clipsCount,
    hlReel,
    clips,
    analyseStatus,
    onAnalysisStart,
    isDemo,
    handleDemoAnalysisActions,
  } = props;

  const eventId = useParams()?.eventId;

  const queryParameters = new URLSearchParams(window.location.search);
  const startAnalysis = queryParameters.get('startAnalysis') === 'true';

  const currentEvent = useEventStore((state) => state.currentEvent);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const [isShowAnalyseBtn, setIsShowAnalyseBtn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isAnalysed = useMemo(() => {
    return analyseStatus === 'analysed';
  }, [analyseStatus]);

  // demo data
  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const [demoStatus, setDemoStatus] = useState(
    isAnalysed ? analyseStatus : 'not-analysed',
  );
  const [demoHlReel, setDemoHlReel] = useState(
    isAnalysed ? { ...demoData.hlReel, shots: [] } : demoData.hlReel ?? {},
  );

  const { demoSelectedClips, demoAnalysedClips } = useMemo(() => {
    const demoSelectedClips = [];
    const demoAnalysedClips = [];

    const allSelectedClips = getDemoSelected(demoData.clips);

    allSelectedClips.forEach((clip) => {
      if (clip.isAnalysed) demoAnalysedClips.push(clip);
      else demoSelectedClips.push(clip);
    });

    return { demoSelectedClips, demoAnalysedClips };
  }, [demoData.clips]);

  const [demoClips, setDemoClips] = useState({
    newSelects: demoStatus !== 'analysing' ? demoSelectedClips : [],
    convertingSelects: [],
    analysingSelects: [],
    finishedSelects: demoAnalysedClips,
    timedOutSelects: [],
    errorSelects: [],
  });

  useEffect(() => {
    if (startAnalysis) {
      onClickAnalyse();

      window.history.pushState(null, '', URL.VIDEO_BUILDER);
    }
  }, [startAnalysis]);

  const imitateAnalysing = () => {
    setIsShowAnalyseBtn(false);
    setDemoStatus('analysing');
    demoData.event.stage = 'analysis';
    demoData.isAssetsProcessed = false;

    handleDemoAnalysisActions(demoData);

    setDemoClips({
      ...demoClips,
      newSelects: [],
      convertingSelects: demoSelectedClips,
    });

    const interval = setInterval(() => {
      setDemoClips((prevClips) => {
        const nextClips = { ...prevClips };

        // move from converting to analysing
        if (!isEmpty(nextClips.convertingSelects)) {
          const clip = nextClips.convertingSelects.shift();
          nextClips.analysingSelects.push(clip);
        }

        // move from analysing to finished
        else if (!isEmpty(nextClips.analysingSelects)) {
          const clip = nextClips.analysingSelects.shift();
          clip.isAnalysed = true;
          nextClips.finishedSelects.push(clip);
        }

        // all clips are 'analysed'
        else {
          clearInterval(interval);

          // finish 'analysing' highlight reel
          setDemoHlReel({ ...demoHlReel, shots: [] });

          setDemoStatus('analysed');
          demoData.isAssetsProcessed = true;
          // before updating clips in demoData – concat finishedSelects with unselected clips to get all clips
          demoData.clips = nextClips.finishedSelects.concat(
            getDemoSelected(demoData.clips, true),
          );

          handleDemoAnalysisActions(demoData, true);
        }

        return nextClips;
      });
    }, 1000);
  };

  const onSuccessfulAnalysisStart = () => {
    trackAnalysisStartSuccess(currentEvent, startAnalysis);

    // remove eventId from localStorage arrays (for GA)

    const analysedProjects = (
      JSON.parse(localStorage.getItem('analysedProjects')) ?? []
    ).filter((id) => id !== eventId);
    localStorage.setItem('analysedProjects', JSON.stringify(analysedProjects));

    const clipsWarningProjects = (
      JSON.parse(localStorage.getItem('clipsWarningProjects')) ?? []
    ).filter((id) => id !== eventId);
    localStorage.setItem(
      'clipsWarningProjects',
      JSON.stringify(clipsWarningProjects),
    );
  };

  const onClickAnalyse = async () => {
    setIsSubmitting(true);

    // demo event
    if (isDemo) {
      // reset the clips & hl reel
      setDemoClips({
        ...demoClips,
        convertingSelects: demoSelectedClips,
        finishedSelects: [],
      });
      setDemoHlReel({ ...demoData.hlReel });

      setTimeout(() => {
        onSuccessfulAnalysisStart();

        imitateAnalysing();

        setIsSubmitting(false);
      }, 500);
    }

    // usual event
    else {
      onAnalysisStart();

      const { success, errMsg } = await analyseAll(eventId);

      if (success) {
        onSuccessfulAnalysisStart();

        await updateCurrentEvent();
      } else {
        trackAnalysisStartError(errMsg);

        if (errMsg) toast.error(errMsg);
      }

      setIsSubmitting(false);
    }
  };

  const { analyseText, progress } = getAnalyseText({
    hlReel: isDemo ? demoHlReel : hlReel,
    clips: isDemo ? demoClips : clips,
    peopleCount,
  });

  const status = useMemo(() => {
    return isShowAnalyseBtn
      ? 'not-analysed'
      : isDemo
        ? demoStatus
        : analyseStatus;
  }, [analyseStatus, demoStatus, isDemo, isShowAnalyseBtn]);

  switch (status) {
    case 'not-analysed':
      return (
        <React.Fragment>
          <div>
            Analyse <b>{getPluralPhrase(clipsCount, 'clip')}</b> for{' '}
            <b>{getPluralPhrase(peopleCount, 'person')}</b>. Our AI will look
            for the best clips to add to your Highlight Reels wildcard shots.
          </div>

          <FancyBtn
            text="Analyse"
            isSubmitting={isSubmitting}
            onClick={onClickAnalyse}
          />
        </React.Fragment>
      );

    case 'analysing':
      return (
        <React.Fragment>
          <SellingPoint text={analyseText} />

          <div className="w-full flex flex-col gap-4">
            <ProgressBar progress={progress} />

            <div className="text-center">
              <div className="font-bold uppercase">Analysing</div>

              {!isDemo && (
                <Link
                  to={`/${eventId}${URL.VB_ANALYSIS}`}
                  onClick={trackAnalysisClickSeeProgress}
                >
                  See progress
                </Link>
              )}
            </div>
          </div>
        </React.Fragment>
      );

    case 'analysed':
      return (
        <React.Fragment>
          <div>
            {analyseText}{' '}
            {(!isDemo || !demoData.isLimited) && (
              <span
                className="underline cursor-pointer text-primary-900 hover:opacity-80"
                onClick={() => setIsShowAnalyseBtn(true)}
              >
                Analyse again
              </span>
            )}
          </div>
        </React.Fragment>
      );
    default:
      return null;
  }
};

export default AnalyseStep;
