import React, { useState, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import { isMobile, isTablet } from 'react-device-detect';

import { getThumbnail } from '../../Utils';

import Logo from '../../components/logo';
import PageSpinner from '../../components/pageSpinner';
import ShareFooter from '../../components/shareFooter';

import config from '../../config';
import { S3_BUCKET_URL } from '../../constants';

import { getCreationWithData } from '../../services/api/creations';
import { getEventPublic } from '../../services/api/events';
import { incrementViewedMetric } from '../../services/api/videoCreationMetrics';

import withRouter from '../../withRouter';

import ShareBtnsContainer from './shareBtnsContainer';

const { featureToggles } = config;

const isTouchDevice = isMobile || isTablet;

const ShareVideo = (props) => {
  const { eventId, faceId } = props.params;

  const [event, setEvent] = useState(null);
  const [person, setPerson] = useState(null);
  const [isPlayed, setIsPlayed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const videoRef = useRef(null);

  useEffect(() => {
    const onMount = async () => {
      if (isLoading) {
        const event = await getEventPublic(eventId);
        const person = await getCreationWithData(eventId, faceId);

        setEvent(event);
        setPerson(person);

        setIsLoading(false);
      }
    };

    onMount();
  });

  const { face, creation, metrics, videoUrl } = useMemo(() => {
    return {
      face: person?.face ?? {},
      creation: person?.creations[0] ?? {},
      metrics: person?.metrics ?? {},
      videoUrl: `${S3_BUCKET_URL}.files${!featureToggles.paywall || event?.paid ? person?.finalVideoUri : person?.previewVideoUri}#t=0.5`,
    };
  }, [event, person]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const handlePlayVideo = async () => {
        await incrementViewedMetric(metrics.metricId);

        // open video in full screen on mobile
        if (isTouchDevice && !isPlayed) {
          var video = document.getElementById('video');

          if (video.requestFullscreen) video.requestFullscreen();
          else if (video.webkitRequestFullscreen)
            video.webkitRequestFullscreen();
          else if (video.msRequestFullScreen) video.msRequestFullScreen();

          setIsPlayed(true);
        }
      };

      videoElement.addEventListener('play', handlePlayVideo);
      return () => {
        videoElement.removeEventListener('play', handlePlayVideo);
      };
    }
  }, [isPlayed, metrics.metricId]);

  return (
    <PageSpinner
      isLoading={isLoading}
      noTips
      isFullPage
      isPageContainer
      containerClass="mt-[5vw] sm:mt-7 py-0"
    >
      <div className="flex flex-col px-5 sm:px-20 py-7 gap-4 sm:gap-8 bg-grey-100 text-center rounded-md shadow-grey-1">
        <Logo withTagLine />

        <h2 className="m-0">
          <b>{face.name}</b> at {event?.name}
        </h2>

        <video
          id="video"
          className="w-[calc(var(--page-width)_-_2.5rem)] sm:w-[calc(var(--page-width)_-_10rem)] h-[calc((var(--page-width)_-_2.5rem)_*_9_/_16)] sm:h-[calc((var(--page-width)_-_10rem)_*_9_/_16)] rounded-md"
          src={videoUrl}
          poster={creation.frameId ? getThumbnail(creation.frameId) : null}
          controls
          ref={videoRef}
        />

        <div className="flex justify-between items-center">
          <div>{moment(creation.created).fromNow()}</div>

          <ShareBtnsContainer
            event={event}
            person={person}
            videoUrl={videoUrl}
          />
        </div>
      </div>

      <ShareFooter />
    </PageSpinner>
  );
};

export default withRouter(ShareVideo);
