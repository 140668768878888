import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getEventImg, getShareLink } from '../../Utils';

import Logo from '../../components/logo';
import PageSpinner from '../../components/pageSpinner';
import ShareFooter from '../../components/shareFooter';

import { getCreationResults } from '../../services/api/creations';
import { getEventPublic } from '../../services/api/events';

import withRouter from '../../withRouter';

const ShareHub = (props) => {
  const { eventId } = props.params;

  const [event, setEvent] = useState({});
  const [generatedFaces, setGeneratedFaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const onMount = async () => {
      if (isLoading) {
        const event = await getEventPublic(eventId);
        const generatedFaces = await getCreationResults(eventId, true);

        setEvent(event);
        setGeneratedFaces(generatedFaces);

        setIsLoading(false);
      }
    };

    onMount();
  });

  const logo = event.logo && getEventImg('logo', event.logo);

  return (
    <PageSpinner
      isLoading={isLoading}
      noTips
      isFullPage
      isPageContainer
      containerClass="mt-[5vw] sm:mt-7 py-0"
    >
      <div className="flex flex-col px-5 sm:px-20 py-7 gap-4 sm:gap-8 bg-grey-100 text-center rounded-md shadow-grey-1">
        <Logo withTagLine />

        <h2 className="m-0">
          <b>{event.name}</b> has created {generatedFaces.length} personalised
          videos!
        </h2>

        <hr />

        <div className="flex flex-col items-center gap-7">
          {logo && (
            <img
              src={logo}
              alt="Logo"
              className="w-[50vw] sm:w-[13vw] h-[50vw] sm:h-[13vw] object-cover rounded-md"
            />
          )}

          <h4>Click faces to find videos for:</h4>

          <div className="flex flex-wrap justify-center gap-6">
            {generatedFaces.map((faceObj) => {
              const face = faceObj.face;

              return (
                <Link
                  to={getShareLink(eventId, face.elementId)}
                  target="_blank"
                  className="w-[var(--hub-face-size)] text-center no-underline transition-all duration-500 hover:scale-110 hover:opacity-100"
                >
                  <img
                    src={face.img}
                    className="w-[var(--hub-face-size)] h-[var(--hub-face-size)] rounded-full object-cover"
                    alt={`${face.name} Icon`}
                  />
                  <div className="w-full mt-2 text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {face.name}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      <ShareFooter />
    </PageSpinner>
  );
};

export default withRouter(ShareHub);
