import React from 'react';
import { PiPlus } from 'react-icons/pi';

const DemoAddOverlay = (props) => {
  const { element, addClass } = props;

  if (element.isSelected) return null;

  return (
    <div
      className={`${addClass} group absolute flex items-center justify-center bg-black-overlay-600 text-center text-white`}
    >
      <div className="absolute w-4/5 transition-all group-hover:opacity-0">
        Click to add
      </div>

      <PiPlus className="absolute text-[3em] transition-all opacity-0 group-hover:opacity-100" />
    </div>
  );
};

export default DemoAddOverlay;
