import React from 'react';
import { PiInfoFill } from 'react-icons/pi';

import PopoverTrigger from '../../../components/popoverTrigger';

const HeadingWithInfo = (props) => {
  const { heading, infoHeader, infoBody } = props;

  return (
    <h4 className="flex items-center gap-1">
      <div>{heading}</div>

      <PopoverTrigger
        content={
          <span className="leading-none">
            <PiInfoFill
              className="hover:fill-[url('#icon-primary-gradient')] cursor-pointer p-0"
              size="1.2em"
            />
          </span>
        }
        placement="bottom"
        addClass="big-popover"
        popoverHeader={infoHeader}
        popoverBody={<div className="flex flex-col gap-2">{infoBody}</div>}
      />
    </h4>
  );
};

export default HeadingWithInfo;
