import React from 'react';
import { useParams } from 'react-router-dom';

import SellingPoint from '../../../components/sellingPoint';

import { URL } from '../../../constants';

import { getShareText } from '../components/getText';
import TimelineBtn from '../components/timelineBtn';

const ShareStep = (props) => {
  const { peopleCount } = props;

  const eventId = useParams()?.eventId;

  const { shareText } = getShareText({ peopleCount });

  return (
    <React.Fragment>
      <SellingPoint text={shareText} />

      <TimelineBtn
        type="share"
        heading="Share"
        subheading="Share via email or copy share link"
        link={`/${eventId}${URL.VB_SHARE}`}
      />
    </React.Fragment>
  );
};

export default ShareStep;
