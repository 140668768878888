import config from '../../config';
import {
  getAccessTokenClient,
  getApiKeyClient,
  getCheckinClient,
} from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const accessTokenClient = () => getAccessTokenClient(baseUrl);
const checkinClient = () => getCheckinClient(baseUrl);

export const privateFileCreate = async (files) => {
  try {
    await accessTokenClient().put('api/files', JSON.stringify({ files }), {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log('File saved');
  } catch (err) {
    throw new Error(`Error saving file: ${err.message}`);
  }
};

export const checkinFileCreate = async (files) => {
  try {
    await checkinClient().put('api/files', JSON.stringify({ files }), {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log('File saved');
  } catch (err) {
    throw new Error(`Error saving file: ${err.message}`);
  }
};

export const fileDelete = async (elementId) => {
  try {
    await accessTokenClient().delete(`api/files/${elementId}`);
    console.log('File deleted');
  } catch (err) {
    throw new Error(`Error deleting file: ${err.message}`);
  }
};

export const getCheckinFaceVideos = async (eventId, parentElementId) => {
  try {
    const response = await checkinClient().get(
      `api/event/${eventId}/face/${parentElementId}/files?fileKind=video-footage`,
    );
    return response.data.files;
  } catch (err) {
    throw new Error(`Error getting face videos: ${err.message}`);
  }
};

export const getCheckinFacePhotos = async (eventId, parentElementId) => {
  try {
    const response = await checkinClient().get(
      `api/event/${eventId}/face/${parentElementId}/files?fileType=image`,
    );
    return response.data.files;
  } catch (err) {
    throw new Error(`Error getting face images: ${err.message}`);
  }
};
