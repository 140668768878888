import React from 'react';
import { PiPlus } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { trackCreateProject } from '../../analytics';

import { URL } from '../../constants';

const CreateEventBtn = () => {
  return (
    <Link
      className="group relative w-[var(--event-block-width)] h-[var(--event-block-height)] flex flex-col items-center justify-center gap-2 no-underline bg-white rounded-md cursor-pointer hover:opacity-100"
      to={URL.DASH_EVENT_BUILDER}
      onClick={() => trackCreateProject('Home')}
    >
      <PiPlus className="w-8 h-8 sm:w-10 sm:h-10 fill-[url('#icon-purple-gradient')] transition-all group-hover:scale-[200%] group-hover:mt-8" />

      <div className="text-md font-bold uppercase bg-gradient-purple-light bg-clip-text text-transparent transition-all group-hover:invisible group-hover:opacity-0">
        Create Project
      </div>
    </Link>
  );
};

export default CreateEventBtn;
