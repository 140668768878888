import config from '../../config';
import { getAccessTokenClient } from '../client/http';

const {
  apiBaseUrl: { queues: baseUrl },
} = config;

const accessTokenClient = () => getAccessTokenClient(baseUrl);

export const queuesDelete = async (elementId) => {
  try {
    await accessTokenClient().delete(`api/queues/${elementId}`);
    console.log('Queues deleted');
  } catch (err) {
    throw new Error(`Error deleting queues: ${err.message}`);
  }
};
