import React from 'react';
import classNames from 'classnames';
import { isEmpty, round, msecToSec, getThumbnail } from '../../../../Utils';

import { Spinner } from '../../../../components';
import TemplatePlaceholder from '../../../../components/icons/templatePlaceholder';
import PopoverTrigger from '../../../../components/popoverTrigger';
import CreationPreview from './creationPreview';

const HlReelPreview = (props) => {
  const { hlReel, openedPerson, selectedClip, handleChangeSelection } = props;

  // array with 5 empty blocks – shows while highlight reel is processing
  let previewArray = new Array(5).fill({});
  let isProcessing = true;

  if (hlReel?.shots) {
    previewArray = [];
    isProcessing = false;
    let i = 0;

    hlReel.shots.forEach((shot) => {
      const shotDuration = msecToSec(shot.segment.durationMilliseconds);

      // all shots between wildcards to combine in 1 block
      if (shot.type === 'shot') {
        if (!previewArray[i]) {
          // if block i doesn't exist – create one with first shot data
          const firstSelect = {
            type: 'selects',
            duration: shotDuration,
            startTime: msecToSec(shot.segment.startTimestampMilliseconds),
            endTime: msecToSec(shot.segment.endTimestampMilliseconds),
            frameIds: [shot.frameId],
          };
          previewArray.push(firstSelect);
        } else {
          // if exists – update some data of the block
          previewArray[i].duration += shotDuration;
          previewArray[i].endTime = msecToSec(
            shot.segment.endTimestampMilliseconds,
          );
          previewArray[i].frameIds.push(shot.frameId);
        }
      } else {
        let clipId = null;
        let frameId = null;
        let elementId = null;
        let name = null;
        let startTime = null;

        // if there's an opened person – save its clip id and frame id
        if (openedPerson) {
          clipId = openedPerson.selectedClips?.find(
            (clip) => clip.wildCardShotIndex === shot.shotIndex,
          )?.clipId;

          const foundClip =
            openedPerson.clips.find((clip) => clip.elementId === clipId) ?? {};

          frameId = foundClip.frameId;

          // next data is used for final creation video preview
          const fileNameParts = foundClip.name?.split('@') ?? [];
          elementId = foundClip.parentElementId;
          name = fileNameParts[0] + '@' + fileNameParts[1] + '.mp4';
          startTime = msecToSec(foundClip.segment?.startTimestampMilliseconds);
        }

        // if the shot is a wildcard – add it to array
        previewArray.push({
          type: 'wildcard',
          duration: shotDuration,
          shotIndex: shot.shotIndex,
          clipId,
          frameId,
          elementId,
          name,
          startTime,
        });
        // jump 2 steps – 1 for wildcard added above, 1 for next added select
        i = i + 2;
      }
    });
  }

  // on big screens 5 blocks fit within a page, on small – 2
  const isFitting =
    previewArray.length <= 2 ||
    (window.innerWidth >= 500 && previewArray.length <= 5);

  const containerClass = classNames({
    'group flex items-center p-4 gap-4 overflow-x-auto': true,
    'justify-center': isFitting,
  });

  const previewSchema = (
    <div
      className="relative w-full rounded-md border-1 border-grey-200 border-solid"
      id={openedPerson ? 'hl-reel-preview-person' : null}
    >
      <div className={containerClass}>
        {previewArray.map((block, index) => {
          const thumbnailClass = classNames({
            'rounded-md object-cover': true,
            'w-[32vw] min-w-[32vw] h-[calc(32vw_*_9_/_16)] sm:w-[13vw] sm:min-w-[13vw] sm:h-[calc(13vw_*_9_/_16)]':
              block.type === 'wildcard',
            'w-[25vw] min-w-[25vw] h-[calc(25vw_*_9_/_16)] sm:w-[10vw] sm:min-w-[10vw] sm:h-[calc(10vw_*_9_/_16)]':
              block.type !== 'wildcard',
          });

          const placeholderClass = classNames({
            'hover:border-primary-900 hover:border-solid': !openedPerson,
          });

          // if block is selects – get middle frame id from array, or use block frame id
          const frameId =
            block.type === 'selects'
              ? block.frameIds[Math.round((block.frameIds.length - 1) / 2)]
              : block.frameId;

          let isMatching = false;
          let isFaded = false;
          let wildcardPopoverText = null;

          if (!isEmpty(selectedClip) && block.type === 'wildcard') {
            // don't allow to select wildcard if the duration doesn't match
            if (block.duration > selectedClip.duration) isFaded = true;
            else {
              isMatching = true;
              wildcardPopoverText =
                block.clipId === selectedClip.clipId
                  ? 'The selected clip is already inserted here. Click to remove it.'
                  : 'Click to insert the selected clip here.';
            }
          }

          // img thumbnail
          let thumbnailImg = (
            <div className={`${thumbnailClass} bg-grey-200`} />
          );

          if (block.type === 'wildcard' && frameId)
            thumbnailImg = (
              <img
                src={getThumbnail(frameId)}
                className={thumbnailClass}
                alt="Wildcard Thumbnail"
              />
            );
          else if (block.type === 'wildcard' && !frameId) {
            thumbnailImg = (
              <TemplatePlaceholder
                duration={block.duration}
                popoverBody={wildcardPopoverText}
                hidePopover={openedPerson && !wildcardPopoverText}
                addClass={`${thumbnailClass} ${placeholderClass}`}
              />
            );
            // clear wildcardPopoverText so that popover doesn't show on thumbnailImg, as TemplatePlaceholder has it's own popover
            wildcardPopoverText = null;
          } else if (!openedPerson)
            thumbnailImg = (
              <img
                src={getThumbnail(frameId)}
                className={thumbnailClass}
                alt="Shot Thumbnail"
              />
            );

          const blockClass = classNames({
            'group relative transition-all duration-300 hover:z-10': true,
            'cursor-pointer animate-glowing hover:scale-110': isMatching,
            'cursor-default hover:scale-110 hover:shadow-salmon group-[:has(.group:hover)]:[&:not(:hover)]:blur-xs':
              !openedPerson,
          });

          const overlayClass = classNames({
            'absolute flex flex-col items-center justify-center p-2 text-white text-center pointer-events-none': true,
            'bg-black-overlay-200': !!frameId,
          });

          return (
            <div
              key={index}
              className={`${thumbnailClass} ${blockClass}`}
              onClick={() => isMatching && handleChangeSelection(block)}
            >
              {/* faded overlay */}
              {isFaded && (
                <PopoverTrigger
                  content={
                    <div
                      className={`${thumbnailClass} block absolute bg-grey-overlay-800 z-1`}
                    />
                  }
                  placement="top"
                  popoverBody="You cannot use the selected clip for this wildcard as it's not long enough"
                />
              )}

              <div className={`${thumbnailClass} ${overlayClass}`}>
                {frameId && (
                  <React.Fragment>
                    <div className="text-capitalize fw-bold">{block.type}</div>
                    <div className="text-xs">
                      {round(block.duration)} seconds
                    </div>
                  </React.Fragment>
                )}

                {isProcessing && <Spinner color="white" />}
              </div>

              <PopoverTrigger
                content={thumbnailImg}
                placement="top"
                popoverBody={wildcardPopoverText}
                hidePopover={!wildcardPopoverText}
                delay={{ show: 300, hide: 0 }}
              />
            </div>
          );
        })}
      </div>

      {!isFitting && (
        <div className="absolute top-0 left-0 w-full h-full bg-white-edges rounded-md pointer-events-none z-10" />
      )}
    </div>
  );

  return (
    <React.Fragment>
      {openedPerson && (
        <CreationPreview
          openedPerson={openedPerson}
          hlReel={hlReel}
          previewArray={previewArray}
        />
      )}

      {previewSchema}
    </React.Fragment>
  );
};

export default HlReelPreview;
