import React, { useEffect, useState, FC } from 'react';
import { Link } from 'react-router-dom';

import { countSecsFromNow } from '../../Utils';
import { sendVerificationEmail } from '../../UtilsEmail';

import {
  trackLandEmailConfirmationPage,
  trackEmailConfirmationClickResend,
  trackEmailConfirmationClickGoBack,
} from '../../analytics';

import emailVerificationImg from '../../assets/email-verification.png';
import passwordImg from '../../assets/password.png';

import { Button } from '../../components';
import PageContainer from '../../components/pageContainer';

import { URL, RESEND_EMAIL_IN_SEC } from '../../constants';

import { useUserStore } from '../../stores/user';

interface EmailVerificationProps {
  type: string;
}

const EmailVerification: FC<EmailVerificationProps> = (props) => {
  const { type } = props;

  const sessionEmail = sessionStorage.getItem('email') ?? '';

  const user = useUserStore((state) => state.user);
  const email = user?.email ?? sessionEmail;

  const [resendMoment, setResendMoment] = useState(
    new Date(new Date().getTime() + RESEND_EMAIL_IN_SEC * 1000),
  );
  const [resendEmailIn, setResendEmailIn] = useState(RESEND_EMAIL_IN_SEC);

  let graphicImg = emailVerificationImg;
  let backLink = URL.ENTER_EMAIL;

  if (type === 'reset-password') {
    graphicImg = passwordImg;
    backLink = `${URL.LOGIN}?forgotPassword=true`;
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (resendMoment) {
        const secondsLeft = countSecsFromNow(resendMoment);
        setResendEmailIn(secondsLeft <= 0 ? 0 : secondsLeft);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [resendMoment]);

  const resendEmail = async () => {
    trackEmailConfirmationClickResend();

    if (type === 'verification') sendVerificationEmail(email);

    setResendMoment(
      new Date(new Date().getTime() + RESEND_EMAIL_IN_SEC * 1000),
    );
  };

  useEffect(() => {
    trackLandEmailConfirmationPage();
  }, []);

  return (
    <PageContainer addClass="h-[calc(100vh_-_var(--header-height))] sm:justify-center">
      <div className="flex flex-col items-center justify-center p-6 sm:px-[10%] sm:py-16 gap-4 bg-primary-50 text-center rounded-md">
        <img
          className="w-44"
          src={graphicImg}
          alt="Email Confirmation Graphic"
        />

        <h4>Check your email</h4>

        <div>
          Click on the {type.replace(/-/g, ' ')} link sent to <b>{email}</b>. Be
          sure to check your spam if it doesn't arrive in your inbox!
        </div>

        <div className="flex flex-col items-center gap-2">
          <div>Still can't find the email?</div>

          <Button
            className="w-44"
            onClick={resendEmail}
            isThin
            disabled={resendEmailIn > 0}
          >
            Resend email
          </Button>

          <div className="text-sm italic">
            {resendEmailIn > 0
              ? `You will be able to resend the email in ${resendEmailIn} seconds.`
              : 'You are able to resend the email now.'}
          </div>
        </div>

        <div>
          Entered incorrect email address?{' '}
          <Link to={backLink} onClick={trackEmailConfirmationClickGoBack}>
            Go back
          </Link>
        </div>
      </div>
    </PageContainer>
  );
};
export default EmailVerification;
