import React from 'react';
import { Form } from 'react-bootstrap';

const FormFeedback = (props) => {
  const { error } = props;

  return (
    <Form.Control.Feedback className={error ? 'show' : ''} type="invalid">
      {error}
    </Form.Control.Feedback>
  );
};

export default FormFeedback;
