import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { PiShareFat, PiCheck, PiEnvelope } from 'react-icons/pi';

import { Spinner } from '../';
import { checkFaceEmail, sendFaceEmail } from '../../UtilsEmail';
import { FACE_EMAIL_STATUS } from '../../constants';
import PopoverTrigger from '../popoverTrigger';

import FormFeedback from './formFeedback';

const EmailSendGroup = (props) => {
  const {
    type,
    event,
    face,
    faceEmails,
    setFaceEmails,
    afterEmailSend,
    btnText,
    className,
    isDemo,
  } = props;
  const emailObj = faceEmails[face.elementId] || {};
  const onChangeEmail = (e, id) => {
    faceEmails[id].email = e.target.value;
    faceEmails[id].err = null;

    setFaceEmails({ ...faceEmails });
  };

  const handleSend = async (e) => {
    const { isSuccess } = await sendFaceEmail(
      e,
      type,
      event,
      face,
      faceEmails,
      setFaceEmails,
      true,
    );

    if (isSuccess) {
      if (afterEmailSend) {
        afterEmailSend();
      }
    }
  };

  const btnTextClass = 'absolute transition-all';

  return (
    <Form
      className={className}
      noValidate
      onSubmit={(e) =>
        checkFaceEmail(e, face.elementId, faceEmails, setFaceEmails)
      }
    >
      <fieldset
        className="flex flex-row gap-2"
        disabled={emailObj.status === FACE_EMAIL_STATUS.SENDING || isDemo}
      >
        <Form.Group className="grow">
          <InputGroup>
            <InputGroup.Text className="h-12">
              <PiEnvelope />
            </InputGroup.Text>

            <Form.Control
              type="email"
              name="email"
              value={emailObj.email}
              onChange={(e) => onChangeEmail(e, face.elementId)}
              placeholder={`Enter email address to ${btnText.toLowerCase()}...`}
              isInvalid={emailObj.err}
              required
              className="h-12"
            />
          </InputGroup>

          <FormFeedback error={emailObj.err} />
        </Form.Group>

        <PopoverTrigger
          content={
            <button
              className="w-12 h-12 flex items-center justify-center bg-black text-white rounded-md hover:opacity-80 disabled:opacity-65"
              variant=""
              onClick={handleSend}
            >
              <PiShareFat
                className={`${btnTextClass} ${
                  [
                    FACE_EMAIL_STATUS.NOT_SENT,
                    FACE_EMAIL_STATUS.RESEND,
                  ].includes(emailObj.status)
                    ? 'opacity-100'
                    : 'opacity-0'
                }`}
                size={20}
              />

              <Spinner
                color="white"
                className={`${btnTextClass} ${
                  emailObj.status === FACE_EMAIL_STATUS.SENDING
                    ? 'opacity-100'
                    : 'opacity-0'
                }`}
              />
              <PiCheck
                className={`${btnTextClass} ${
                  emailObj.status === FACE_EMAIL_STATUS.SENT
                    ? 'opacity-100'
                    : 'opacity-0'
                }`}
                size={20}
              />
            </button>
          }
          hidePopover={!isDemo}
          placement="top"
          popoverHeader="Unable to share"
          popoverBody="Sharing email is not available in the sample project."
        />
      </fieldset>
    </Form>
  );
};

export default EmailSendGroup;
