import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

import eventCustomField from '../../../assets/event-custom-field.png';
import eventImages from '../../../assets/event-images.png';

import DraggerSimplified from '../../../components/draggerSimplified';
import HeadingWithInfo from './headingWithInfo';

const EventCheckInSection = (props) => {
  const {
    bannerUrl,
    logoUrl,
    customFieldLabel,
    onChangeImg,
    onChangeCustomFieldLabel,
    isBrand,
  } = props;

  return (
    <div className="flex flex-col gap-6">
      <HeadingWithInfo
        heading="Check-In Page"
        infoHeader="Setting up your Check-In page"
        infoBody={
          <>
            <div>
              The banner and logo will be shown at the top of the Check-In page.
            </div>

            <img src={eventImages} alt="Banner & Logo Graphic" />

            <hr />

            <div>
              The Custom Field (if existing) will be shown as an additional
              optional field for attendees to fill on the Check-In page.
            </div>

            <img src={eventCustomField} alt="Custom Field Graphic" />
          </>
        }
      />

      <div
        className={`flex flex-col gap-6 ${
          isBrand ? '' : 'sm:flex-row sm:gap-10'
        }`}
      >
        <div
          className={`${isBrand ? '' : 'w-full sm:w-1/2'} flex flex-col gap-2`}
        >
          <h5>Banner</h5>

          <DraggerSimplified
            type="image"
            imgUrl={bannerUrl}
            isDashboard
            isColumn={isBrand}
            onDrop={(file) => onChangeImg(file, true)}
          />

          <div className="text-sm italic">
            Pro Tip: for the best experience, make sure your banner fits a 16:9
            aspect ratio.
          </div>
        </div>

        <div
          className={`${isBrand ? '' : 'w-full sm:w-1/2'} flex flex-col gap-2`}
        >
          <h5>Logo</h5>

          <DraggerSimplified
            type="image"
            imgUrl={logoUrl}
            isDashboard
            isColumn={isBrand}
            onDrop={(file) => onChangeImg(file, false)}
          />

          <div className="text-sm italic">
            Pro Tip: for the best experience, make sure your logo fits a 1:1
            aspect ratio.
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <h5>Custom Field</h5>

        <div className="text-sm italic">
          Add a custom property to appear on check-in page
        </div>

        <FloatingLabel label="Custom field label">
          <Form.Control
            type="text"
            name="customFieldLabel"
            value={customFieldLabel}
            onChange={onChangeCustomFieldLabel}
            placeholder="Custom field label"
          />
        </FloatingLabel>
      </div>
    </div>
  );
};

export default EventCheckInSection;
