import React from 'react';
import { PiSmileySadFill } from 'react-icons/pi';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '../../components';
import PageContainer from '../../components/pageContainer';

import { URL } from '../../constants';

const Error = (props) => {
  const { code } = props;

  const navigate = useNavigate();

  let heading = null;
  let subheading = null;

  if (code === 403) {
    heading = 'Access denied';
    subheading = "Sorry, you don't have access to this page.";
  } else if (code === 404) {
    heading = 'Page not found';
    subheading = (
      <>
        Oops, we can't seem to find the page you are looking for, but you can
        find lots of other things on the <Link to={URL.HOME}>Home</Link> page.
      </>
    );
  }

  return (
    <PageContainer addClass="h-screen flex flex-col items-center justify-center mt-0 p-0 gap-8 text-center">
      <h1 className="text-4xl bg-gradient-primary-right bg-clip-text text-transparent">
        {code || (
          <PiSmileySadFill
            className="fill-[url('#icon-primary-gradient')]"
            size={100}
          />
        )}
      </h1>

      {heading && <h2>{heading}</h2>}

      {subheading && <div className="w-full sm:w-1/2">{subheading}</div>}

      <div className="w-full flex justify-center gap-4">
        <Button className="w-1/2 sm:w-40" onClick={() => navigate(URL.HOME)}>
          Return Home
        </Button>
        <Button
          variant="pink"
          className="w-1/2 sm:w-40"
          onClick={() => navigate(URL.HELP)}
        >
          Help
        </Button>
      </div>
    </PageContainer>
  );
};

export default Error;
