import React, { useState, useEffect, useRef } from 'react';
import auth0 from 'auth0-js';
import moment from 'moment';

import { Link } from 'react-router-dom';

import { getEventImg, goTo } from '../../Utils';

import { Button } from '../../components';
import CheckinStepper from '../../components/checkinStepper';
import Logo from '../../components/logo';
import ErrorModal from '../../components/modals/errorModal';
import PageSpinner from '../../components/pageSpinner';

import config from '../../config';

import { getEventPublic } from '../../services/api/events';
import {
  checkInFace,
  getFaceWithFiles,
  getFaceWithFilesById,
} from '../../services/checkInService';

import { useCheckinStore } from '../../stores/checkin';
import withRouter from '../../withRouter';

import DetailsStage from './components/detailsStage';
import EmailStage from './components/emailStage';
import VideoStage from './components/videoStage';

const { featureToggles } = config;

const getFaceFromAuth = async (webAuth, event, setBearerToken) => {
  // Check if we can retrieve the face from the user
  return new Promise((resolve, reject) => {
    webAuth.checkSession({}, (err, authResult) => {
      if (err) {
        console.error('check session error', err);
        return resolve(null);
      }

      webAuth.client.userInfo(authResult.accessToken, async (err, user) => {
        if (err) {
          console.error('Get user info error', err);
          return resolve(null);
        }
        setBearerToken(authResult.idToken);
        const face = await checkInFace(event, user.email);
        return resolve(face);
      });
    });
  });
};

const EventCheckIn = (props) => {
  const { eventId, faceId } = props.params;

  const queryParameters = new URLSearchParams(window.location.search);
  const reqShots = queryParameters.get('reqShots');

  const checkInEmail = useCheckinStore((state) => state.checkInEmail);

  const [event, setEvent] = useState({});
  const [bannerUrl, setBannerUrl] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const face = useCheckinStore((state) => state.face);
  const setFace = useCheckinStore((state) => state.setFace);
  const setBearerToken = useCheckinStore((state) => state.setBearerToken);

  const didMountRef = useRef(false);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const fetchedEvent = await getEventPublic(eventId);
        if (!fetchedEvent) {
          throw new Error('Event data is null or undefined');
        }

        setEvent(fetchedEvent);

        const now = moment().utc();
        // Check event is still ongoing
        if (!faceId && moment(fetchedEvent.endDate).isBefore(now, 'day')) {
          setError({
            heading: 'Sorry, this event has ended',
            subheading:
              'But you can learn more about CrowdClip&reg; <a href="https://crowdclip.com/">here</a>.',
            onClick: () => {
              goTo('https://crowdclip.com/');
            },
          });
          return;
        }

        setBannerUrl(getEventImg('banner', fetchedEvent.banner));
        setLogoUrl(getEventImg('logo', fetchedEvent.logo));

        if (face) return;

        if (faceId) {
          const faceResult = await getFaceWithFilesById(
            fetchedEvent.eventId,
            faceId,
          );

          if (!faceResult || !reqShots)
            setError({
              heading: 'Sorry, this link is invalid',
              subheading:
                'But you can learn more about CrowdClip&reg; <a href="https://crowdclip.com/">here</a>.',
              onClick: () => {
                goTo('https://crowdclip.com/');
              },
            });

          setFace(faceResult);

          return;
        }

        const webAuth = new auth0.WebAuth({
          domain: process.env.REACT_APP_AUTH0_DOMAIN,
          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
          responseType: 'token id_token',
          scope: 'openid profile email',
          redirectUri: `${window.location.origin}/complete-check-in?eventId=${fetchedEvent.eventId}`,
        });

        let faceResult = featureToggles.auth0
          ? await getFaceFromAuth(webAuth, fetchedEvent, setBearerToken)
          : await checkInFace(fetchedEvent, checkInEmail);

        if (faceResult) {
          faceResult = await getFaceWithFiles(fetchedEvent.eventId, faceResult);
          setFace(faceResult);
        }
      } catch (error) {
        setError({
          heading: 'Something went wrong!',
          subheading: 'Please reload and try again!',
          onClick: () => window.location.reload(),
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (!didMountRef.current) {
      fetchEventData();
      didMountRef.current = true;
    }
  }, [eventId, faceId, face, checkInEmail, setFace, setBearerToken, reqShots]);

  const refetchFace = async () => {
    const faceTemp = await getFaceWithFiles(eventId, face, true);
    setFace(faceTemp);
  };

  if (error) {
    return (
      <ErrorModal
        show={!!error}
        heading={error?.heading ?? 'Something went wrong!'}
        subheading={error?.subheading ?? 'Please reload and try again!'}
        btn={
          <Button
            className="w-full"
            onClick={() => (error?.onClick ?? window.location.reload)()}
          >
            OK :(
          </Button>
        }
      />
    );
  }

  const renderSecondScreen = () => {
    if (!face) {
      return (
        <>
          <CheckinStepper eventId={eventId} activeStep={0} />
          <EmailStage event={event} />
        </>
      );
    }
    // for missing video page (all event types) – show video stage
    else if (!!faceId)
      return (
        <VideoStage
          event={event}
          face={face}
          isMissingVideo={true}
          refetchFace={refetchFace}
          reqShots={reqShots}
        />
      );
    // for brand event – show video stage
    else if (event.type === 'brand')
      return (
        <>
          <CheckinStepper eventId={eventId} activeStep={1} />
          <VideoStage event={event} face={face} refetchFace={refetchFace} />
        </>
      );
    // else if videographer or demo – show details stage
    else
      return (
        <>
          <CheckinStepper eventId={eventId} activeStep={1} />
          <DetailsStage event={event} face={face} />
        </>
      );
  };

  return (
    <PageSpinner isLoading={isLoading} noTips isFullPage>
      <img
        className="w-full h-[calc(var(--wide-page-width)_*_9_/_16)] object-cover"
        src={bannerUrl}
        alt="Event Banner"
      />

      <div className="relative top-[-1rem] sm:top-[-10rem] w-[var(--page-width)] sm:w-[500px] flex flex-col items-center mx-auto p-4 gap-8 bg-white rounded-md shadow-grey-2">
        <img
          className="w-24 h-24 mt-[-4rem] mb-[-1rem] object-center object-cover rounded-md overflow-hidden"
          src={logoUrl}
          alt="Event Logo"
        />

        <h1 className="w-full text-center text-[1.25rem] font-normal">
          <b>{!!faceId ? 'Upload clips' : 'Check-in'}</b> to generate your
          personalised video at <b>{event.name}</b>
        </h1>

        {renderSecondScreen()}

        <Link
          to="https://crowdclip.com"
          target="_blank"
          className="block text-center no-underline hover:opacity-100"
        >
          Powered by <Logo width="80px" noLink />
        </Link>
      </div>
    </PageSpinner>
  );
};

export default withRouter(EventCheckIn);
