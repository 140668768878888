import ReactGA from 'react-ga4';

export const trackCreationClickDownload = (type) => {
  ReactGA.event({
    category: 'Attendee',
    action: `creation_click_${type}_download`,
  });
};

export const trackCreationDownloadSuccess = (type) => {
  ReactGA.event({
    category: 'Attendee',
    action: `creation_${type}_download_success`,
  });
};

export const trackCreationDownloadError = (type) => {
  ReactGA.event({
    category: 'Attendee',
    action: `creation_${type}_download_error`,
  });
};

export const trackCreationClickNativeShare = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'creation_click_native_share',
  });
};

export const trackCreationNativeShareSuccess = (label) => {
  ReactGA.event({
    category: 'Attendee',
    action: 'creation_native_share_success',
    label: label,
  });
};

export const trackCreationNativeShareError = () => {
  ReactGA.event({
    category: 'Attendee',
    action: 'creation_native_share_error',
  });
};
