import React, { useState } from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import { Form } from 'react-bootstrap';
import {
  PiArrowSquareOut,
  PiDownloadSimple,
  PiCheckCircle,
} from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty, getCheckInLink } from '../../Utils';
import { Button } from '../../components';
import { QRCode, downloadQRCode } from '../../components/qrCode';
import SubmitBtn from '../../components/submitBtn';
import TemplateExample from '../../components/templateExample';

import {
  sendMsNotification,
  sendMsConfirmation,
} from '../../services/api/emails';
import { useUserStore } from '../../stores/user';

const MsForm = (props) => {
  const { eventName, eventId } = props;

  const user = useUserStore((state) => state.user);

  const queryParameters = new URLSearchParams(window.location.search);
  const step = parseInt(queryParameters.get('step'));

  const [activeStep, setActiveStep] = useState(step || 2);
  const [selectsText, setSelectsText] = useState('');
  const [templateText, setTemplateText] = useState('');
  const [templateOption, setTemplateOption] = useState('');
  const [isEmailSending, setIsEmailSending] = useState(false);

  const steps = [
    { label: 'Set Up Account' },
    { label: 'Create Project' },
    { label: 'Share Face Check-In' },
    { label: 'Upload Template Link/s' },
    { label: 'Upload Selects Link/s' },
    { label: 'Confirm Upload Completion' },
  ];

  const checkInPageLink = getCheckInLink(eventId);

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = async () => {
    const userEventText =
      'You have a new request for <b>Managed Services</b> from the following user:<br />' +
      `<b>User</b>: ${user.email} (${user.session.userId})<br />` +
      `<b>Event</b>: ${eventName} (${eventId})<br /><br />`;

    const selectsEmailText =
      'Here are the link/s for <b>Selects</b>:<br />' +
      `${selectsText}<br /><br />`;

    const templateEmailText =
      (templateOption === 'own'
        ? 'Here are the link/s for <b>Template</b>:'
        : 'The user requested a <b>Template</b>.') +
      (templateText && `<br />${templateText}`);

    const text =
      userEventText +
      selectsEmailText +
      (isEmpty(templateOption) ? '' : templateEmailText);

    setIsEmailSending(true);

    // send notification email to CC
    const isSuccessCC = await sendMsNotification(
      eventId,
      text.replace(/\n/g, '<br />'),
    );

    // send confirmation email to user
    const isSuccessUser = await sendMsConfirmation(
      user.email,
      eventId,
      checkInPageLink,
    );

    setIsEmailSending(false);

    if (isSuccessCC && isSuccessUser) handleNext();
    else toast.error('Something went wrong. Try again later');
  };

  const NavBtns = ({ type, disabled }) => {
    const onClickBack = () =>
      type === 'template' ? setTemplateOption('') : handlePrevious();

    const onClickNext = () =>
      activeStep === 4 ? handleSubmit() : handleNext();

    return (
      <div className="w-full flex justify-center gap-4">
        {activeStep !== 2 && (
          <Button variant="secondary" className="w-1/2" onClick={onClickBack}>
            Back
          </Button>
        )}

        <SubmitBtn
          title={activeStep === 4 ? 'Submit' : 'Next'}
          type="button"
          onClick={onClickNext}
          isProcessing={isEmailSending}
          isDisabled={disabled}
          className="w-1/2"
        />
      </div>
    );
  };

  const event = { eventId, name: eventName };

  const renderContent = () => {
    switch (activeStep) {
      case 2:
        return (
          <React.Fragment>
            <h5>
              <b>Copy and share</b> the check-in page link; or{' '}
              <b>download and share</b> the QR code to allow attendees to
              check-in using their face (photo or selfie)
            </h5>

            <Link to={checkInPageLink} target="_blank">
              {checkInPageLink}

              <PiArrowSquareOut
                size="1.5rem"
                className="ml-1.5 align-text-bottom"
              />
            </Link>

            <div className="w-full flex flex-col items-center justify-center p-6 gap-2 rounded-md border-1 border-grey-900 border-solid">
              <QRCode event={event} link={checkInPageLink} />

              <Button
                variant="light-grey"
                className="w-36"
                onClick={() => downloadQRCode(event)}
              >
                <PiDownloadSimple className="text-[1.5rem] text-primary-900" />
              </Button>
            </div>

            <NavBtns type="faces" />
          </React.Fragment>
        );

      case 3:
        if (templateOption === 'make')
          return (
            <React.Fragment>
              <h5>
                Great! CrowdClip&reg; will now create a 59 second template for
                your project with 2 wildcard/shot placeholders (5 seconds each)
              </h5>

              <Form.Control
                as="textarea"
                rows={5}
                type="text"
                name="template"
                value={templateText}
                onChange={(e) => setTemplateText(e.target.value)}
                placeholder="Tell us what you would want or not want to be included on the template"
                required
              />

              <NavBtns type="template" />
            </React.Fragment>
          );

        if (templateOption === 'own')
          return (
            <React.Fragment>
              <h5>Add the link to a video file to be used as your template</h5>

              <div className="flex flex-col gap-2">
                <div>
                  To create wildcard placeholders, make sure you include the
                  word “SHOT” in large capital text against a white background,
                  ideally either in Arial or Helvetica.
                </div>

                <div>Here's an example:</div>

                <TemplateExample />
              </div>

              <Form.Control
                as="textarea"
                rows={5}
                type="text"
                name="template"
                value={templateText}
                onChange={(e) => setTemplateText(e.target.value)}
                placeholder="Add link to the video file or Drive/Dropbox folder"
                required
              />

              <div className="text-sm italic">
                Please ensure that you've changed the folder permissions to
                Public or shared with{' '}
                <a href="mailto:hello@crowdclip.com">hello@crowdclip.com</a>.
              </div>

              <NavBtns type="template" disabled={isEmpty(templateText)} />
            </React.Fragment>
          );

        return (
          <React.Fragment>
            <h5>
              Would you like CrowdClip&reg; to create a template on your behalf?
            </h5>

            <Button
              className="w-full"
              onClick={() => setTemplateOption('make')}
            >
              Yes, make a template for me
            </Button>

            <Button
              variant="pink"
              className="w-full"
              onClick={() => setTemplateOption('own')}
            >
              No, I want to use my own template
            </Button>

            <Button
              variant="secondary"
              className="w-1/2 mt-8"
              onClick={handlePrevious}
            >
              Back
            </Button>
          </React.Fragment>
        );

      case 4:
        return (
          <React.Fragment>
            <h5>
              Add link/s to video file folders containing close up of
              individuals you wish the AI to create personalised videos of
            </h5>

            <Form.Control
              as="textarea"
              rows={5}
              type="text"
              name="selects"
              value={selectsText}
              onChange={(e) => setSelectsText(e.target.value)}
              placeholder="You may insert multiple links here"
              required
            />

            <div className="text-sm italic">
              Please ensure that you've changed the folder permissions to Public
              or shared with{' '}
              <a href="mailto:hello@crowdclip.com">hello@crowdclip.com</a>.
            </div>

            <NavBtns type="selects" disabled={isEmpty(selectsText)} />
          </React.Fragment>
        );

      case 5:
        return (
          <React.Fragment>
            <PiCheckCircle className="w-24 h-24 text-[var(--success-color)]" />

            <h5>
              Thank you! Our <b>CrowdClip&reg; Managed Services</b> team has
              received your assets and will now begin the process of creating
              personalised videos.
            </h5>

            <div>
              Hold tight! You may now close this window and allow 24-48H for us
              to create your project. We will <b>notify you via email</b> once
              the assets are ready for your review!
            </div>
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <h3>Upload Buddy</h3>

      <div className="flex flex-col sm:flex-row border-1 border-grey-100 border-solid rounded-md shadow-grey-2">
        <Stepper
          className="w-full sm:w-1/3 flex flex-col p-6 sm:px-12 sm:py-8 gap-2 sm:gap-8 bg-grey-100 rounded-tl-md rounded-bl-md"
          activeStep={activeStep}
          orientation="vertical"
          connector={false}
        >
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel
                sx={{
                  '.MuiStepIcon-root': { fontSize: '2rem' },
                  '.Mui-completed > svg': { color: 'var(--success-color)' },
                  '.Mui-active': { color: 'var(--primary-color) !important' },
                  '.Mui-active.MuiStepLabel-label': { fontWeight: 'bold' },
                  '.Mui-disabled > .MuiStepIcon-root': {
                    color: '#fff',
                    border: '1px solid var(--grey)',
                    borderRadius: '50%',
                  },
                  '.Mui-disabled .MuiStepIcon-text': { fill: '#000' },
                }}
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Form className="w-full sm:w-2/3 flex justify-center p-6 sm:px-12 sm:py-8">
          <fieldset
            className="w-full flex flex-col items-center gap-4 text-center"
            disabled={isEmailSending}
          >
            {renderContent()}
          </fieldset>
        </Form>
      </div>
    </div>
  );
};

export default MsForm;
