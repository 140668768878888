import axios from 'axios';
import config from '../../config';
import { EMAILS, MS_RECIPIENTS } from '../../constants';
import { getApiKeyClient } from '../client/http';

const {
  apiBaseUrl: {
    elements: elementsBaseUrl,
    auth: authBaseUrl,
    bffApi: bffBaseUrl,
  },
} = config;

const elementsApiKeyClient = () => getApiKeyClient(elementsBaseUrl);
const authApiKeyClient = () => getApiKeyClient(authBaseUrl);

export const sendEmail = async (emailData) => {
  try {
    const response = await elementsApiKeyClient().post('api/emails', emailData);
    return response.data.isSuccess;
  } catch (err) {
    throw new Error(`Error sending email: ${err.message}`);
  }
};

export const sendMsNotification = async (eventId, text, addRecipients) => {
  let oneEmailSent = false;

  const recipientList = addRecipients
    ? MS_RECIPIENTS.concat(addRecipients)
    : MS_RECIPIENTS;

  await Promise.all(
    recipientList.map(async (recipient) => {
      const emailData = {
        recipientEmail: recipient,
        templateId: EMAILS.MS_NOTIFICATION,
        dynamicTemplateData: { event_name: eventId, text },
      };

      const isSuccess = await sendEmail(emailData);

      // at least one email is sent successfully
      if (isSuccess) oneEmailSent = true;
    }),
  );

  return oneEmailSent;
};

export const sendMsConfirmation = async (email, eventId, share_url) => {
  if (!email) return true;

  const emailData = {
    recipientEmail: email,
    templateId: EMAILS.MS_CONFIRMATION,
    dynamicTemplateData: { event_name: eventId, share_url },
  };

  const isSuccess = await sendEmail(emailData);

  return isSuccess;
};

export const sendUserVerification = async (email, baseUrl) => {
  const emailData = {
    email,
    baseUrl,
  };

  try {
    const response = await authApiKeyClient().post(
      'api/user/send-verification',
      emailData,
    );
    return response.data.isSuccess;
  } catch (err) {
    throw new Error(`Error sending user verification email: ${err.message}`);
  }
};

export const subscribeContact = async (listId, user) => {
  try {
    await axios.post(
      `${bffBaseUrl}/email-lists/subscribe?listId=${listId}&email=${user.email}&firstName=${user.firstName}&lastName=${user.lastName}`,
    );
  } catch (err) {
    throw new Error(`Error subscribing contact: ${err.message}`);
  }
};
