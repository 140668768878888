import React from 'react';
import classNames from 'classnames';

import FormFeedback from '../../../components/form/formFeedback';

const EventOrientationSelector = (props) => {
  const { orientation, setOrientation, error } = props;

  const eventModes = [
    { type: 'portrait', ratio: '9 x 16' },
    { type: 'landscape', ratio: '16 x 9' },
  ];

  return (
    <div className="flex flex-col gap-2">
      <h5>Orientation*</h5>

      <div className="text-sm italic">
        Template and user UGC will be converted to the selected orientation.
      </div>

      <div className="flex items-center gap-4">
        {eventModes.map((mode, index) => {
          const modeIconClass = classNames({
            'flex flex-col items-center justify-center gap-0.5 rounded-md border-1 border-solid cursor-pointer hover:bg-primary-50 hover:text-primary-900 hover:border-primary-900': true,
            'bg-primary-50 text-primary-900 border-primary-translucent':
              mode.type === orientation,
            'bg-grey-100 border-grey-900': mode.type !== orientation,
            'w-[var(--mode-icon-side-a)] sm:w-[calc(var(--mode-icon-side-a)_/_2)] h-[var(--mode-icon-side-b)] sm:h-[calc(var(--mode-icon-side-b)_/_2)]':
              mode.type === 'landscape',
            'w-[var(--mode-icon-side-b)] sm:w-[calc(var(--mode-icon-side-b)_/_2)] h-[var(--mode-icon-side-a)] sm:h-[calc(var(--mode-icon-side-a)_/_2)]':
              mode.type === 'portrait',
          });

          return (
            <div
              className={modeIconClass}
              onClick={() => setOrientation(mode.type)}
              key={index}
            >
              <h6 className="text-sm font-bold capitalize">{mode.type}</h6>
              <div className="text-sm">{mode.ratio}</div>
            </div>
          );
        })}
      </div>

      <FormFeedback error={error} />
    </div>
  );
};

export default EventOrientationSelector;
