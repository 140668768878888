import React, { useState, useEffect, useMemo } from 'react';
import { Modal, FloatingLabel, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty, getFileImg } from '../../../../Utils';

import { Spinner } from '../../../../components';
import DraggerSimplified from '../../../../components/draggerSimplified';
import EmailField from '../../../../components/form/emailField';
import HeadingBtnGroup from '../../../../components/headingBtnGroup';
import SubmitBtn from '../../../../components/submitBtn';
import UploadInfo from '../../../../components/uploadInfo';

import { privateFaceMerge } from '../../../../services/api/faces';
import { uploadFile } from '../../../../services/uploadFileService';

import { validateForm } from './personValidations';

const EditPersonModal = ({
  event,
  people,
  openedPersonId,
  setOpenedPersonId,
  fetchPeopleData,
}) => {
  const openedPerson = useMemo(() => {
    const foundPerson = people.find(
      (person) => person.face.elementId === openedPersonId,
    );

    return foundPerson ?? null;
  }, [people, openedPersonId]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [instagram, setInstagram] = useState('');
  const [customFieldData, setCustomFieldData] = useState('');
  const [uploadingImages, setUploadingImages] = useState({});

  const [errors, setErrors] = useState({});

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!isEmpty(openedPerson?.face)) {
      setName(openedPerson.face.name ?? '');
      setEmail(openedPerson.face.email ?? '');
      setInstagram(openedPerson.face.instagram ?? '');
      setCustomFieldData(openedPerson.face.customFieldData ?? '');
    }
  }, [openedPersonId]);

  const onChange = (e, setValue) => {
    let value = e.target.value;
    setValue(value);
    setErrors({});
  };

  const handleUploadImage = async (file, fileList) => {
    try {
      // set uploading images data
      const uploadingImagesTemp = { ...uploadingImages };

      fileList.forEach((file) => {
        uploadingImagesTemp[file.uid] = { id: file.uid, name: file.name };
      });

      setUploadingImages(uploadingImagesTemp);

      const parentElementId = openedPerson.face.elementId;

      await uploadFile({
        file,
        eventId: event.eventId,
        parentElementId,
        onProgress,
      });

      fetchPeopleData(true);
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong. Try again later');
    }
  };

  const onProgress = (event) => {
    setUploadingImages((previousImages) => ({
      ...previousImages,
      [event.id]: event,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const errors = await validateForm(name, email);

    if (!isEmpty(errors)) setErrors(errors);
    else {
      setIsSaving(true);

      try {
        const personData = {
          ...openedPerson.face,
          name,
          email,
          instagram,
          modified: new Date(),
        };

        await privateFaceMerge(event.eventId, personData);

        fetchPeopleData();

        toast.success(
          <div>
            <b>{name}</b> details are updated
          </div>,
        );
      } catch (error) {
        console.error(error);
        toast.error('Something went wrong. Try again later');
      }

      setIsSaving(false);
    }
  };

  const filteredUploading = useMemo(() => {
    const imagesIds = openedPerson?.files.map((file) => file.elementId) ?? [];

    return Object.keys(uploadingImages)
      .map((id) => uploadingImages[id])
      .filter((img) => img && !imagesIds.includes(img.elementId));
  }, [openedPerson?.files, uploadingImages]);

  const infoHeading =
    'When <b>uploading an image</b>, keep the following in mind to ensure recognition success:';

  const infoPoints = [
    'A ‘person’ is an individual’s photo (JPEG or PNG) labeled with a name',
    'The photo should be obstruction-free (no glasses/sunglasses, hats or masks), well-lit and clear',
  ];

  if (!openedPersonId) return null;

  return (
    <Modal
      show={!isEmpty(openedPerson)}
      onHide={() => setOpenedPersonId(null)}
      size="lg"
      centered
    >
      <Modal.Header className="flex items-center justify-between">
        <Modal.Title>Update person – {openedPerson.face.name}</Modal.Title>

        <HeadingBtnGroup
          group={people}
          id={openedPersonId}
          setId={setOpenedPersonId}
        />
      </Modal.Header>

      <Modal.Body>
        <Form noValidate onSubmit={onSubmit}>
          <fieldset className="flex flex-col gap-4" disabled={isSaving}>
            <div className="flex items-center gap-4 sm:gap-6">
              <img
                src={getFileImg(openedPerson.files[0])}
                className="w-16 h-16 sm:w-44 sm:h-44 object-cover rounded-full"
                alt={`${openedPerson.face.name} Icon`}
              />

              <div className="flex flex-col gap-3 w-[calc(100%_-_4rem_-_1rem)] sm:w-[calc(100%_-_11rem_-_1.5rem)]">
                <h5>Details</h5>

                <div className="flex flex-col sm:flex-row gap-3">
                  <FloatingLabel label="Full name*" className="w-full sm:w-1/2">
                    <Form.Control
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => onChange(e, setName)}
                      placeholder="Please enter a full name"
                      isInvalid={errors.name}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </FloatingLabel>

                  <EmailField
                    className="w-full sm:w-1/2"
                    value={email}
                    isInvalid={errors.email}
                    errMsg={errors.email}
                    onChange={(e) => onChange(e, setEmail)}
                    customLabel="Email address"
                  />
                </div>

                <div
                  className={
                    event.customFieldLabel
                      ? 'flex flex-col sm:flex-row gap-3'
                      : ''
                  }
                >
                  <FloatingLabel
                    label="Instagram"
                    className={
                      event.customFieldLabel ? 'w-full sm:w-1/2' : 'w-full'
                    }
                  >
                    <Form.Control
                      type="text"
                      name="instagram"
                      value={instagram}
                      onChange={(e) => onChange(e, setInstagram)}
                      placeholder="Instagram"
                    />
                  </FloatingLabel>

                  {event.customFieldLabel && (
                    <FloatingLabel
                      label={event.customFieldLabel}
                      className="w-full sm:w-1/2"
                    >
                      <Form.Control
                        type="text"
                        name="customFieldData"
                        value={customFieldData}
                        onChange={(e) => onChange(e, setCustomFieldData)}
                        placeholder={event.customFieldLabel}
                      />
                    </FloatingLabel>
                  )}
                </div>
              </div>
            </div>

            {openedPerson.videoLink ? (
              <div className="text-start">
                <b>Share link</b>:{' '}
                <Link to={openedPerson.videoLink} target="_blank">
                  {openedPerson.videoLink}
                </Link>
              </div>
            ) : (
              <React.Fragment>
                <div className="flex flex-col gap-3">
                  <UploadInfo
                    isCollapse
                    type="people"
                    infoPoints={infoPoints}
                    collapseText="Additional photos (optional)"
                    heading={infoHeading}
                  />

                  <div className="text-sm italic">
                    To increase AI accuracy we recommend uploading up to 3
                    photos of each attendee.
                  </div>

                  <DraggerSimplified
                    type="image"
                    heading="Photo upload (1 and more)"
                    isMultiple={true}
                    onDrop={handleUploadImage}
                  />
                </div>

                {(openedPerson.files?.length > 1 ||
                  !isEmpty(filteredUploading)) && (
                  <div className="flex flex-col gap-3">
                    <h6>All uploaded photos ({openedPerson.files.length})</h6>

                    <div className="flex flex-wrap gap-2">
                      {openedPerson.files.map((img) => (
                        <img
                          key={img.elementId}
                          src={getFileImg(img)}
                          className="w-16 h-16 sm:w-20 sm:h-20 object-cover rounded-full"
                          alt={`${openedPerson.face.name}`}
                        />
                      ))}

                      {filteredUploading.map((img) => (
                        <Spinner
                          key={img.elementId}
                          customSize="w-16 h-16 sm:w-20 sm:h-20"
                          color="primary"
                        />
                      ))}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}

            <SubmitBtn
              title="Save"
              isProcessing={isSaving}
              isDisabled={!isEmpty(errors)}
            />
          </fieldset>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditPersonModal;
