import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { PiUserRectangle, PiVideo, PiUsers } from 'react-icons/pi';

import ContentHeader from '../../components/contentHeader';
import PageSpinner from '../../components/pageSpinner';

import { getEventPublic } from '../../services/api/events';

import { useEventStore } from '../../stores/event';
import { useUserStore } from '../../stores/user';

import withRouter from '../../withRouter';

import MsForm from './msForm';
import MsRequestForm from './msRequestForm';

const ManagedServices = (props) => {
  const { eventId } = props.params;

  const user = useUserStore((state) => state.user);
  const setEventId = useEventStore((state) => state.setEventId);

  const [eventName, setEventName] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const onMount = async () => {
      if (isLoading) {
        if (eventId) {
          const event = await getEventPublic(eventId);

          // if user logged in & events is existing – show MS form
          if (user && event) {
            setEventName(event.name);
            setShowForm(true);
          }
        }
        setIsLoading(false);
      }
    };

    setEventId(eventId);
    onMount();
  });

  const reqsBlocks = [
    {
      icon: <PiUserRectangle />,
      heading: 'Clips',
      subheading:
        'Add a link to your Google Drive, Dropbox folder or similar, where you’ve digitally saved your clips',
    },
    {
      icon: <PiVideo />,
      heading: 'Highlight Reel',
      subheading:
        'Upload your final template/highlight reel file, with wildcard/shot placeholders',
    },
    {
      icon: <PiUsers />,
      heading: 'People',
      subheading:
        'Guest registers using a clear photo or selfie, through a shareable QR Code or URL provided by CrowdClip&reg;',
    },
  ];

  const renderReqsBlocks = () => {
    return (
      <div className="flex flex-col sm:flex-row gap-4 sm:gap-10">
        {reqsBlocks.map((block) => (
          <div
            className="w-full sm:w-1/3 flex flex-row sm:flex-col rounded-md shadow-grey-2"
            key={block.heading}
          >
            <div className="flex items-center justify-center p-10 bg-primary-50 text-[4rem] text-primary-900 rounded-tl-md rounded-tr-md">
              {block.icon}
            </div>

            <div className="min-h-32 flex flex-col p-4 gap-2 text-center">
              <div className="font-bold">{block.heading}</div>
              <div className="text-sm opacity-50">
                {parse(block.subheading)}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <PageSpinner
      isLoading={isLoading}
      noTips
      isPageContainer
      containerClass="gap-8"
    >
      <ContentHeader
        title="Upload Buddy"
        subtitle="Simply <i>Upload Review Go</i><br /><br />We all know as videographers the uploading of files is the painful bit and takes the longest. We are built by videographers and value your precious time as much as you do! Using this feature, you can simply add all the assets as links in one go and we will notify you when everything is uploaded and ready for your review."
      />

      <div className="flex flex-col gap-4">
        <h3>What you need</h3>

        {renderReqsBlocks()}
      </div>

      {showForm ? (
        <MsForm eventName={eventName} eventId={eventId} />
      ) : (
        <MsRequestForm />
      )}
    </PageSpinner>
  );
};

export default withRouter(ManagedServices);
