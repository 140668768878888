import ReactGA from 'react-ga4';

// user guiding modal

export const trackUGModalOpen = () => {
  ReactGA.event({
    category: 'User',
    action: 'user_guiding_modal_open',
  });
};

export const trackUGModalClose = () => {
  ReactGA.event({
    category: 'User',
    action: 'user_guiding_modal_close',
  });
};

export const trackUGModalSelectRole = (role) => {
  ReactGA.event({
    category: 'User',
    action: 'user_guiding_modal_select_role',
    label: role,
  });
};

// onboarding modal

export const trackOnboardingModalOpen = () => {
  ReactGA.event({
    category: 'User',
    action: 'onboarding_modal_open',
  });
};

export const trackOnboardingModalClose = () => {
  ReactGA.event({
    category: 'User',
    action: 'onboarding_modal_close',
  });
};

export const trackOnboardingModalVideoWatchFully = () => {
  ReactGA.event({
    category: 'User',
    action: 'onboarding_modal_video_watch_fully',
  });
};

export const trackOnboardingModalVideoWatchPartially = () => {
  ReactGA.event({
    category: 'User',
    action: 'onboarding_modal_video_watch_partially',
  });
};

export const trackOnboardingModalClickRemindMeLater = () => {
  ReactGA.event({
    category: 'User',
    action: 'onboarding_modal_click_remind_me_later',
  });
};

// quickstart pack

export const trackQuickstartPackDownload = (source) => {
  ReactGA.event({
    category: 'User',
    action: 'quickstart_pack_download',
    label: source,
  });
};

// sample project

export const trackSampleProjectOpen = () => {
  ReactGA.event({
    category: 'User',
    action: 'sample_project_open',
  });
};

export const trackDemoStartAgain = () => {
  ReactGA.event({
    category: 'User',
    action: 'demo_click_start_again',
  });
};

export const trackDemoUpsellClickNextEventForm = () => {
  ReactGA.event({
    category: 'User',
    action: 'demo_upsell_click_next_event_form',
  });
};

export const trackDemoUpsellSubmitNextEventForm = () => {
  ReactGA.event({
    category: 'User',
    action: 'demo_upsell_submit_next_event_form',
  });
};

export const trackDemoUpsellClickAskCC = () => {
  ReactGA.event({
    category: 'User',
    action: 'demo_upsell_click_ask_cc_[coming_soon]',
  });
};
