import React from 'react';

import {
  isEmpty,
  isPlural,
  getPluralWord,
  getPluralPhrase,
} from '../../../Utils';
import { trackAnalysisShowClipsWarning } from '../../../analytics';
import { URL } from '../../../constants';

// calculate total progress based on each clip progress
const getAnalysisProgress = ({ hlReel, clips, allClipsCount }) => {
  let progress = 0;

  if (clips) {
    // + 5 to count for highlight reel
    const length = allClipsCount + 5;

    progress += (5 * (!hlReel?.shots ? 30 : 100)) / length;
    progress += (clips.convertingSelects.length * 30) / length;
    progress += (clips.analysingSelects.length * 50) / length;
    progress += (clips.finishedSelects.length * 100) / length;

    progress = Math.round(progress);
  }

  return progress;
};

const getNumOfPeopleText = ({
  completePeople,
  incompletePeople,
  generatedPeople,
}) => {
  // count how many people have enough clips
  let completeCount = completePeople?.length || 0;

  const numOfPeople =
    incompletePeople.length === 0
      ? 'all'
      : `${completeCount} of ${completeCount + (incompletePeople?.length || 0) + (generatedPeople?.length || 0)}`;

  return { completeCount, numOfPeople };
};

export const getAnalyseText = ({ hlReel, clips, peopleCount }) => {
  if (isEmpty(clips)) return { tipText: null, progress: 0 };

  const allClipsCount =
    clips.convertingSelects.length +
    clips.analysingSelects.length +
    clips.finishedSelects.length;

  const progress = getAnalysisProgress({ hlReel, clips, allClipsCount });

  // if assets are still processing
  if (progress < 100) {
    return {
      analyseText: (
        <>
          Our AI is analysing a highlight reel and looking for{' '}
          <b>{getPluralPhrase(peopleCount, 'person')}</b> from your{' '}
          <b>{getPluralPhrase(allClipsCount, 'clip')}</b>.
        </>
      ),
      progress,
    };
  } else
    return {
      analyseText: (
        <>
          Analysis of <b>{getPluralPhrase(allClipsCount, 'clip')}</b> for{' '}
          <b>{getPluralPhrase(peopleCount, 'person')}</b> done.
          {!isEmpty(clips.newSelects) && (
            <>
              <br />
              Pending analysis of{' '}
              <b>{getPluralPhrase(clips.newSelects, 'clip', 'new')}</b>.
            </>
          )}
        </>
      ),
    };
};

export const getReviewText = ({
  currentEvent,
  hlReel,
  requiredShots,
  completePeople,
  incompletePeople,
  generatedPeople,
  isAnalysisPage,
  isGenerationStarted,
}) => {
  const eventId = currentEvent.eventId;

  const allPeopleCount =
    completePeople.length + incompletePeople.length + generatedPeople.length;

  // review is complete
  if (isGenerationStarted)
    return {
      reviewText: (
        <>
          Review of <b>{getPluralPhrase(allPeopleCount, 'person')}</b> done.
        </>
      ),
    };

  // if highlight reel is processed and is invalid (brand events)
  if (hlReel.status === 'invalid')
    return {
      reviewAlert: (
        <>
          <b>Your highlight reel has some missing wildcards.</b>
          {!isAnalysisPage ? (
            <>
              <br />
              Review the results for more details.
            </>
          ) : (
            <>
              {' '}
              Re-upload the highlight reel{' '}
              <a href={`/${eventId}${URL.VB_HL_REEL}`}>here</a> to be able to
              proceed.
            </>
          )}
        </>
      ),
    };

  const { completeCount, numOfPeople } = getNumOfPeopleText({
    completePeople,
    incompletePeople,
    generatedPeople,
  });

  // if there's NOT enough clips for a person
  if (completeCount === 0) {
    // add GA event
    const projectsUpd =
      JSON.parse(localStorage.getItem('clipsWarningProjects')) ?? [];

    // check if eventId is not in localStorage clipsWarningProjects – to avoid duplicate events
    if (!projectsUpd.includes(eventId)) {
      trackAnalysisShowClipsWarning(currentEvent);

      projectsUpd.push(eventId);
      localStorage.setItem('clipsWarningProjects', JSON.stringify(projectsUpd));
    }

    return {
      reviewAlert: (
        <>
          <b>Not enough clips</b>. Minimum of{' '}
          {getPluralPhrase(requiredShots, 'clip', 'matching')}{' '}
          {isPlural(requiredShots) ? 'are' : 'is'} required to create a
          personalised highlight reel.
          {!isAnalysisPage && (
            <>
              <br />
              Review the results for more details.
            </>
          )}
        </>
      ),
    };
  }

  // if there's enough clips for at least one person
  return {
    reviewText: (
      <>
        Our AI found enough clips to generate personalised highlight reels for{' '}
        <b>{numOfPeople} checked-in people</b>.
        {!isAnalysisPage && (
          <>
            <br />
            Review the results to continue.
          </>
        )}
      </>
    ),
    numOfPeople,
  };
};

export const getAnalysisPageText = ({
  currentEvent,
  hlReel,
  requiredShots,
  clips,
  completePeople,
  incompletePeople,
  generatedPeople,
  peopleCount,
}) => {
  let heading = 'Analysing';

  if (!clips) return { heading, alertText: null, allowGenerate: false };

  const { analyseText, progress } = getAnalyseText({
    hlReel,
    clips,
    peopleCount,
  });

  // if assets are still processing
  if (progress < 100)
    return { heading, tipText: analyseText, progress, allowGenerate: false };

  heading = 'Review';

  const { reviewAlert, reviewText, numOfPeople } = getReviewText({
    currentEvent,
    hlReel,
    requiredShots,
    completePeople,
    incompletePeople,
    generatedPeople,
    isAnalysisPage: true,
  });

  // if highlight reel is processed and is invalid (brand events)
  if (reviewAlert)
    return { heading, alertText: reviewAlert, allowGenerate: false };

  // if there's enough clips for at least one person
  return { heading, tipText: reviewText, numOfPeople, allowGenerate: true };
};

export const getGenerateText = ({
  completePeople,
  incompletePeople,
  generatedPeople,
}) => {
  const successfulPeople = completePeople.concat(generatedPeople);
  const allPeopleCount = successfulPeople.concat(incompletePeople).length;

  const { numOfPeople } = getNumOfPeopleText({
    completePeople: successfulPeople,
    incompletePeople,
  });

  const progress =
    Math.round((generatedPeople.length / successfulPeople.length) * 100) || 0;

  return {
    generateText: (
      <>
        Generating Highlight Reel{isPlural(successfulPeople)} for{' '}
        <b>
          {numOfPeople} {getPluralWord(allPeopleCount, 'person')}
        </b>
        .
      </>
    ),
    progress,
  };
};

export const getShareText = ({ peopleCount }) => {
  if (peopleCount === 1)
    return {
      shareText: (
        <>Pesonalised Highlight Reel is ready to share with 1 person!</>
      ),
    };

  return {
    shareText: (
      <>
        Pesonalised Highlight Reels are ready to share with {peopleCount}{' '}
        people!
      </>
    ),
  };
};
