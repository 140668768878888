import React from 'react';

const ProgressBar = (props) => {
  const { progress, addClass } = props;

  return (
    <div
      className={`w-full h-16 bg-gray-200 rounded-full overflow-hidden ${
        addClass || ''
      }`}
    >
      <div
        className="flex h-16 p-2 items-center justify-center rounded-l-full bg-blue-green text-xl sm:text-2xl leading-none font-bold text-white"
        style={{ width: `${progress}%` }}
      >
        {progress > 7 && `${progress}%`}
      </div>
    </div>
  );
};

export default ProgressBar;
