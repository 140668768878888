import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

const EmailField = (props) => {
  const {
    className,
    name,
    value,
    isInvalid,
    errMsg,
    customLabel,
    readOnly,
    disabled,
    onChange,
    required,
  } = props;

  return (
    <FloatingLabel
      className={className ?? ''}
      label={`${customLabel ?? 'Enter your email'}${required ? '*' : ''}`}
    >
      <Form.Control
        type="email"
        name={name || 'email'}
        value={value}
        onChange={onChange}
        placeholder="name@example.com"
        isInvalid={isInvalid}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
      />
      {errMsg && (
        <Form.Control.Feedback type="invalid">{errMsg}</Form.Control.Feedback>
      )}
    </FloatingLabel>
  );
};

export default EmailField;
