import ReactGA from 'react-ga4';

export const trackClickPayToRemoveWatermark = () => {
  ReactGA.event({
    category: 'User',
    action: 'click_pay_to_remove_watermark',
  });
};

export const trackPaymentSuccess = () => {
  ReactGA.event({
    category: 'User',
    action: 'payment_success',
  });
};

export const trackPaymentError = () => {
  ReactGA.event({
    category: 'User',
    action: 'payment_error',
  });
};
