import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';

const ContentHeader = (props) => {
  const { title, subtitle, className } = props;

  return (
    <div className={classNames('flex flex-col gap-1', className)}>
      {title && (
        <div className="text-[1.75rem] sm:text-[2.5rem]">{parse(title)}</div>
      )}

      {subtitle && <div className="text-md sm:text-lg">{parse(subtitle)}</div>}
    </div>
  );
};

export default ContentHeader;
