import React, { useState } from 'react';
import { Upload } from 'antd';
import { Form } from 'react-bootstrap';
import { PiCloudArrowUp } from 'react-icons/pi';

import { isEmpty, getBase64 } from '../../Utils';

import { Button } from '../../components';
import ContentHeader from '../../components/contentHeader';
import PageContainer from '../../components/pageContainer';

const Brand = () => {
  const brandLogo = JSON.parse(localStorage.getItem('brandLogo'));

  const [logo, setLogo] = useState(isEmpty(brandLogo) ? {} : brandLogo);
  const [isLogoUpload, setIsLogoUpload] = useState(isEmpty(brandLogo));
  const [isUseLogoCheck, setIsUseLogoCheck] = useState(true);

  const onChange = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      const logo = { name: info.file.name, url: url };

      setLogo(logo);
      localStorage.setItem('brandLogo', JSON.stringify(logo));
      setIsLogoUpload(false);
    });
  };

  const onRemoveLogo = () => {
    setLogo({});
    localStorage.setItem('brandLogo', '{}');
    setIsLogoUpload(true);
  };

  return (
    <PageContainer addClass="gap-6">
      <ContentHeader
        title="Brand"
        subtitle="Upload a logo to overlay on your creations"
      />

      {isLogoUpload ? (
        <div className="flex flex-col gap-2">
          {!isEmpty(logo) && (
            <div className="flex items-center gap-2">
              <div>
                <b>Current logo</b>: {logo.name}
              </div>
              <img src={logo.url} alt="Logo" className="h-5 rounded-sm" />
            </div>
          )}

          <Upload.Dragger
            accept="image/*"
            multiple={false}
            onChange={(info) => onChange(info)}
            className="group"
          >
            <div className="h-80 flex flex-col items-center justify-center gap-4 px-4 text-lg">
              <PiCloudArrowUp
                className="fill-[url('#icon-primary-gradient')] group-hover:animate-jumping"
                size={100}
              />
              <div>Drag and drop or click to upload</div>
            </div>
          </Upload.Dragger>
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <Form.Check id="custom-switch" type="switch" className="m-0">
            <Form.Check.Input
              checked={isUseLogoCheck}
              onChange={() => setIsUseLogoCheck(!isUseLogoCheck)}
            />
            <Form.Check.Label>Use my logo</Form.Check.Label>
          </Form.Check>

          <div className="w-full h-96 flex items-center justify-center p-8 bg-grey-100 rounded-md border-1 border-grey-900 border-solid">
            <img
              className="m-w-[90%] max-h-64 rounded-md"
              src={logo.url}
              alt="Logo"
            />
          </div>
        </div>
      )}

      {!isEmpty(logo) && (
        <div className="flex items-center justify-between">
          <Button variant="secondary" isThin onClick={onRemoveLogo}>
            Remove Logo
          </Button>
          {isLogoUpload ? (
            <Button
              className="w-24"
              isThin
              onClick={() => setIsLogoUpload(false)}
            >
              Cancel
            </Button>
          ) : (
            <Button isThin onClick={() => setIsLogoUpload(true)}>
              Change Logo
            </Button>
          )}
        </div>
      )}
    </PageContainer>
  );
};

export default Brand;
