import React, { useState, useMemo } from 'react';

import { isEmpty } from '../../../../Utils';
import FaceCircle from '../../../../components/faceCircle';
import { deleteFace } from '../../../../services/api/faces';

import EditPersonModal from './editPersonModal';

const PeopleSummary = ({
  event,
  people,
  uploadingPeople,
  deletingPeople,
  setDeletingPeople,
  fetchPeopleData,
  isShowDetails,
  isDemo,
  handleDemoPeople,
}) => {
  const [openedPersonId, setOpenedPersonId] = useState(null);

  const onClickDelete = async (e, person) => {
    e.stopPropagation();

    // add elementId to list of deleting selects
    setDeletingPeople([...deletingPeople, person.elementId]);

    await deleteFace(person.elementId);
    await fetchPeopleData();
  };

  const allPeople = useMemo(() => {
    let peopleArray = (people ?? []).concat(uploadingPeople ?? []);

    peopleArray.sort((one, other) => {
      if (new Date(other.created) > new Date(one.created)) return -1;
      return 1;
    });

    return peopleArray;
  }, [people, uploadingPeople]);

  if (isEmpty(allPeople)) return null;

  return (
    <div className={`flex flex-row flex-wrap ${isShowDetails ? '' : 'gap-6'}`}>
      {allPeople.map((person) => {
        const isUploaded =
          (!isDemo && !!person.files) || (isDemo && !person.isUploading);

        if (isShowDetails && !isUploaded) return null;

        return (
          <FaceCircle
            key={person.face.elementId}
            event={event}
            person={person}
            isBig
            isShowDetails={isShowDetails}
            isSpinner={!isUploaded}
            onClick={
              isUploaded && (() => setOpenedPersonId(person.face.elementId))
            }
            onClickDelete={isUploaded && ((e) => onClickDelete(e, person.face))}
            deletingFaces={deletingPeople}
            isDemo={isDemo}
            handleDemoPeople={handleDemoPeople}
          />
        );
      })}

      <EditPersonModal
        event={event}
        people={people}
        openedPersonId={openedPersonId}
        setOpenedPersonId={setOpenedPersonId}
        fetchPeopleData={fetchPeopleData}
      />
    </div>
  );
};

export default PeopleSummary;
