import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useEventStore } from 'stores/event';
import { getPluralPhrase } from '../../../Utils';

import { trackVBClickUploadMore } from '../../../analytics';

import { URL } from '../../../constants';
import TimelineBtn from '../components/timelineBtn';

const SelectStep = (props) => {
  const {
    peopleCount,
    clipsCount,
    isPeopleUploaded,
    isHlReelUploaded,
    isClipsUploaded,
    isDemo,
  } = props;

  const eventId = useParams()?.eventId;
  const currentEvent = useEventStore((state) => state.currentEvent);

  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};

  const isAssetsUploaded = useMemo(() => {
    return isPeopleUploaded && isHlReelUploaded && isClipsUploaded;
  }, [isPeopleUploaded, isClipsUploaded, isHlReelUploaded]);

  const isAllowUploadMore = useMemo(() => {
    return !isDemo || !demoData.isLimited;
  }, [demoData.isLimited, isDemo]);

  if (!isAssetsUploaded)
    return (
      <React.Fragment>
        <div>
          Upload your highlight reel template, some clips and tell us the people
          to identify in your clips.
        </div>
        <TimelineBtn
          type="people"
          heading="People"
          subheading={
            !isPeopleUploaded
              ? 'Show our AI who to make highlight reels for'
              : `<b>${getPluralPhrase(peopleCount, 'person')}</b> checked-in`
          }
          isComplete={isPeopleUploaded}
          link={`/${eventId}${URL.VB_PEOPLE}`}
        />
        <TimelineBtn
          type="hl-reel"
          heading="Highlight Reel"
          subheading={
            !isHlReelUploaded
              ? 'Upload a highlight reel template'
              : '<b>Highlight reel</b> template uploaded'
          }
          isComplete={isHlReelUploaded}
          link={`/${eventId}${URL.VB_HL_REEL}`}
        />
        <TimelineBtn
          type="clips"
          heading="Clips"
          subheading={
            !isClipsUploaded
              ? 'Upload clips for our AI to find the best moments for each person'
              : `<b>${getPluralPhrase(clipsCount, 'clip')}</b> uploaded`
          }
          isComplete={isClipsUploaded}
          link={`/${eventId}${URL.VB_CLIPS}`}
        />
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <div>
        <b>{getPluralPhrase(peopleCount, 'person')}</b> checked-in.{' '}
        {isAllowUploadMore && (
          <Link
            to={`/${eventId}${URL.VB_PEOPLE}`}
            onClick={() => trackVBClickUploadMore('people', currentEvent)}
          >
            Check-in more people
          </Link>
        )}
      </div>

      <div>
        <b>Highlight reel</b> template uploaded.{' '}
        {isAllowUploadMore && (
          <Link
            to={`/${eventId}${URL.VB_HL_REEL}`}
            onClick={() => trackVBClickUploadMore('hl_reel', currentEvent)}
          >
            Re-upload
          </Link>
        )}
      </div>

      <div>
        <b>{getPluralPhrase(clipsCount, 'clip')}</b> uploaded.{' '}
        {isAllowUploadMore && (
          <Link
            to={`/${eventId}${URL.VB_CLIPS}`}
            onClick={() => trackVBClickUploadMore('clips', currentEvent)}
          >
            Upload more clips
          </Link>
        )}
      </div>
    </React.Fragment>
  );
};

export default SelectStep;
