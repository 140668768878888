import React, { useState, useEffect, useRef } from 'react';

import { getFileUrl } from '../../../../Utils';

import {
  trackAnalysisCreationPreviewPlay,
  trackAnalysisCreationPreviewError,
} from '../../../../analytics';

import { Alert } from '../../../../components';
import SellingPoint from '../../../../components/sellingPoint';

import { useEventStore } from '../../../../stores/event';

const CreationPreview = (props) => {
  const { openedPerson, hlReel, previewArray } = props;

  const currentEvent = useEventStore((state) => state.currentEvent);

  const videoElement = useRef(null);
  const videoSegments = useRef([]);
  const currentSegmentIndex = useRef(0);
  const currentPersonId = useRef(openedPerson.id);

  const [preloadedVideos, setPreloadedVideos] = useState({});
  const [showVideo, setShowVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const hlReelUrl = getFileUrl(hlReel.parentElementId, hlReel.name, 'mp4');

  const getVideoSegments = () => {
    const segments = [];

    previewArray.forEach((el) => {
      if (el.type === 'selects') {
        segments.push({
          src: hlReelUrl,
          start: el.startTime,
          end: el.startTime + el.duration,
        });
      } else if (el.type === 'wildcard') {
        if (!!el.clipId)
          segments.push({
            src: getFileUrl(el.elementId, el.name),
            start: el.startTime,
            end: el.startTime + el.duration,
          });
        else
          setError('Fill all wildcard spots first so we can load the preview.');
      }
    });

    return segments;
  };

  useEffect(() => {
    // reset video state when opened person changes
    const resetVideoState = () => {
      if (videoElement.current) {
        videoElement.current.pause();
        videoElement.current.currentTime = 0;
        videoElement.current.src = '';
        videoElement.current.removeEventListener('timeupdate', onTimeUpdate);
      }
      currentSegmentIndex.current = 0;
      currentPersonId.current = openedPerson.face.elementId;
      setShowVideo(false);
      setIsPlaying(false);
      setError(null);
    };

    // pre-load videos with retry mechanism
    const loadVideos = async () => {
      setIsLoading(true);
      const segments = getVideoSegments();
      const loadedVideos = { ...preloadedVideos };

      const loadVideo = (src, retries = 3) => {
        return new Promise((resolve, reject) => {
          // don't re-load video if it already was loaded before
          if (loadedVideos[src]) resolve({ src, video: loadedVideos[src] });
          else {
            const video = document.createElement('video');
            video.src = src;
            video.crossOrigin = 'anonymous';
            video.onloadeddata = () => resolve({ src, video });

            // try 3 times to reload if it fails
            video.onerror = () => {
              if (retries > 0) {
                setTimeout(
                  () =>
                    loadVideo(src, retries - 1)
                      .then(resolve)
                      .catch(reject),
                  1000,
                );
              } else {
                trackAnalysisCreationPreviewError(currentEvent);

                reject(new Error(`Failed to load video: ${src}`));
              }
            };
            video.load();
          }
        });
      };

      try {
        const loadPromises = segments.map((segment) => loadVideo(segment.src));
        const results = await Promise.all(loadPromises);

        results.forEach(({ src, video }) => {
          loadedVideos[src] = video;
        });

        setPreloadedVideos(loadedVideos);
        videoSegments.current = segments;
      } catch (error) {
        setError(
          "The preview couldn't be loaded, but you still can reselect the clips below and then generate videos.",
        );
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    resetVideoState();
    loadVideos();
  }, [openedPerson, previewArray]);

  const playNextSegment = () => {
    if (currentSegmentIndex.current < videoSegments.current.length) {
      const segment = videoSegments.current[currentSegmentIndex.current];

      const videoBuffer = preloadedVideos[segment.src];

      if (videoBuffer) {
        videoElement.current.src = videoBuffer.src;
        videoElement.current.crossOrigin = 'anonymous';
        videoElement.current.currentTime = segment.start;
        videoElement.current.play().catch((error) => {
          console.error('Error playing video:', error);
        });
        videoElement.current.addEventListener('timeupdate', onTimeUpdate);
      }
    }

    // when all segments played – reset to the beginning and start over
    else {
      currentSegmentIndex.current = 0;
      playNextSegment();
    }
  };

  const onTimeUpdate = () => {
    const segment = videoSegments.current[currentSegmentIndex.current];
    if (videoElement.current.currentTime >= segment.end - 0.3) {
      videoElement.current.removeEventListener('timeupdate', onTimeUpdate);

      if (currentPersonId.current === openedPerson.face.elementId) {
        currentSegmentIndex.current++;
        playNextSegment();
      }
    }
  };

  const onPlayFinalPreview = () => {
    setShowVideo(true);
    setIsPlaying(true);
    currentSegmentIndex.current = 0;

    playNextSegment();

    trackAnalysisCreationPreviewPlay(currentEvent);
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      setIsPlaying(false);
      videoElement.current.pause();
    } else {
      setIsPlaying(true);
      videoElement.current.play().catch((error) => {
        console.error('Error playing video:', error);
      });
    }
  };

  return (
    <React.Fragment>
      {error && <Alert alertData={{ variant: 'warning', text: error }} />}

      <video
        ref={videoElement}
        className={`max-w-full w-[90vw] h-[calc(90vw_*_9_/_16)] sm:w-[40vw] sm:h-[calc(40vw_*_9_/_16)] self-center bg-black rounded-md ${showVideo ? 'block' : 'hidden'}`}
        onClick={togglePlayPause}
        crossOrigin="anonymous"
        muted
      />

      {!showVideo && !error && (
        <SellingPoint
          text={
            <>
              Want a sneak peek of the final video?{' '}
              {isLoading ? (
                <>Give us a second, we're generating a preview for you...</>
              ) : (
                <span
                  className="underline cursor-pointer"
                  onClick={onPlayFinalPreview}
                >
                  Play the preview!
                </span>
              )}
            </>
          }
        />
      )}
    </React.Fragment>
  );
};

export default CreationPreview;
