import React from 'react';
import {
  CheckCircle,
  PlusCircle,
  ArrowCircleRight,
  UsersThree,
  Slideshow,
  CardsThree,
  ListMagnifyingGlass,
  ShareNetwork,
} from '@phosphor-icons/react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

const TimelineBtn = (props) => {
  const { type, heading, subheading, isComplete, link, onClick } = props;

  const getMainIcon = () => {
    const iconClass = 'w-8 h-8 sm:w-10 sm:h-10 mb-2';

    switch (type) {
      case 'people':
        return <UsersThree className={iconClass} />;
      case 'hl-reel':
        return <Slideshow className={iconClass} />;
      case 'clips':
        return <CardsThree className={iconClass} />;
      case 'review':
        return <ListMagnifyingGlass className={iconClass} />;
      case 'share':
        return <ShareNetwork className={iconClass} />;
      default:
        return null;
    }
  };

  const getSideIcon = () => {
    const iconClass = classNames({
      'w-14 h-14 sm:w-16 sm:h-16': true,
      'text-success-900': isComplete,
      'text-grey-900': !isComplete,
    });

    if (isComplete) return <CheckCircle className={iconClass} />;

    if (['people', 'hl-reel', 'clips'].includes(type))
      return <PlusCircle className={iconClass} />;

    return <ArrowCircleRight className={iconClass} />;
  };

  const btnClass = classNames({
    'relative w-80 max-w-full min-h-28 flex flex-col items-center justify-center p-4 sm:p-6 text-center no-underline rounded-md border-5 border-solid shadow-[-7px_7px_8px_-2px_rgba(120,120,120,0.80)] transition-all hover:opacity-100 hover:text-white': true,
    'border-grey-900 text-gray-500 hover:bg-grey-900': !isComplete,
    'border-success-900 text-success-900 hover:bg-success-900': isComplete,
  });

  return (
    <Link to={link} className={btnClass} onClick={onClick}>
      {getMainIcon()}

      {heading && <div className="text-lg font-bold uppercase">{heading}</div>}

      {subheading && <div className="text-sm">{parse(subheading)}</div>}

      <div className="absolute top-[-15px] right-[-15px] rounded-full bg-white">
        {getSideIcon()}
      </div>
    </Link>
  );
};

export default TimelineBtn;
