import React from 'react';
import parse from 'html-react-parser';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const PopoverTrigger = (props) => {
  const {
    content,
    delay,
    placement,
    addClass,
    hidePopover,
    popoverHeader,
    popoverBody,
  } = props;

  const popover = (
    <Popover
      className={`custom-popover
      ${!popoverHeader ? ' no-header' : ''} ${addClass || ''}`}
    >
      {popoverHeader && <Popover.Header>{parse(popoverHeader)}</Popover.Header>}
      {popoverBody && (
        <Popover.Body>
          {typeof popoverBody === 'string' ? parse(popoverBody) : popoverBody}
        </Popover.Body>
      )}
    </Popover>
  );

  if (hidePopover) return content;
  else
    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        delay={delay || { show: 0, hide: 0 }}
        placement={placement}
        overlay={popover}
      >
        {content}
      </OverlayTrigger>
    );
};

export default PopoverTrigger;
