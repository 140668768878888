import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import { goTo } from '../../Utils';

import { unsetUserID } from '../../analytics';
import { URL } from '../../constants';
import { useUserStore } from '../../stores/user';

import ProfileIcon from '../icons/profileIcon';

const ProfileDropdown = () => {
  const user = useUserStore((state) => state.user);

  const onClickLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    goTo(URL.LOGIN);
    unsetUserID();
  };

  return (
    <DropdownButton
      className="profile-dropdown"
      title={<ProfileIcon />}
      variant=""
      align="end"
    >
      <div className="flex items-center px-4 py-1 gap-4">
        <div>
          {(user.firstName || user.lastName) && (
            <div className="font-bold break-all">
              {user.firstName} {user.lastName}
            </div>
          )}
          {user.email && (
            <div className="text-xs break-all opacity-50">{user.email}</div>
          )}
        </div>
      </div>

      <Dropdown.Divider />

      <Dropdown.Item onClick={onClickLogOut}>Log Out</Dropdown.Item>
    </DropdownButton>
  );
};

export default ProfileDropdown;
