import React from 'react';
import classNames from 'classnames';

import { getIsShowSideNav } from '../Utils';

import { useUserStore } from '../stores/user';

const PageContainer = ({ children, addClass }) => {
  const user = useUserStore((state) => state.user);
  const accessToken = useUserStore((state) => state.accessToken);

  const { hasSideNav, isOpenSideNav } = getIsShowSideNav(accessToken, user);
  const isShowSideNav = hasSideNav && isOpenSideNav;

  const contentClass = classNames(
    {
      'w-[90vw] sm:w-[var(--page-width)] flex flex-col mx-[5vw] mt-[var(--header-height)] py-6': true,
      'sm:mx-auto': !isShowSideNav,
      'sm:ml-auto sm:mr-[3.5vw]': isShowSideNav,
    },
    addClass,
  );

  return <div className={contentClass}>{children}</div>;
};

export default PageContainer;
