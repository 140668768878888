import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export const useCheckinStore = create(
  subscribeWithSelector((set, get) => ({
    face: null,
    bearerToken: null,
    checkInEmail: null,
    setBearerToken: (bearerToken) => {
      set((state) => ({ ...state, bearerToken }));
    },
    setFace: (face) => {
      set((state) => ({ ...state, face }));
    },
    setCheckInEmail: (checkInEmail) => {
      set((state) => ({ ...state, checkInEmail }));
    },
  })),
);
