import React from 'react';
import PopoverTrigger from './popoverTrigger';

const PopoverBtn = (props) => {
  const {
    children,
    onClick,
    btnClass,
    isDisabled,
    popoverHeader,
    popoverBody,
  } = props;

  return (
    <PopoverTrigger
      content={
        <button
          className={`${btnClass} rounded-md disabled:opacity-60`}
          onClick={onClick}
          disabled={isDisabled}
        >
          {children}
        </button>
      }
      placement="top"
      hidePopover={!isDisabled}
      popoverHeader={popoverHeader}
      popoverBody={popoverBody}
    />
  );
};

export default PopoverBtn;
