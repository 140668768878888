import React from 'react';
import classNames from 'classnames';
import { PiCaretDown, PiCaretUp } from 'react-icons/pi';

import { isEmpty } from '../../../Utils';

import ThumbnailBlock from './thumbnailBlock';

const ThumbnailsContainer = ({
  groupId = null,
  videos = [],
  isCollapsed = true,
  onClickShowMoreBtn = null,
  selectedClip = {},
  setSelectedClip = () => {},
  deletingClips = [],
  deleteClip = () => {},
  clipSelections = [],
  isBig = false,
  isDemo = false,
  handleDemoClips = () => {},
}) => {
  if (isEmpty(videos)) return null;

  let updatedVideos = [...videos];
  let showMoreBtn = null;

  if (onClickShowMoreBtn && videos.length > 7) {
    updatedVideos = isCollapsed ? videos.slice(0, 6) : videos;
    showMoreBtn = (
      <div
        className={classNames(
          'flex flex-col items-center justify-center text-sm rounded-md border-1 border-grey-900 border-solid object-cover cursor-pointer hover:border-primary-900 hover:text-primary-900',
          {
            'w-[var(--upload-el-width)] h-[var(--upload-thumbnail-height)]':
              isBig,
            'w-[var(--thumbnail-width)] h-[var(--thumbnail-height)]': !isBig,
          },
        )}
        onClick={onClickShowMoreBtn}
      >
        {isCollapsed ? (
          <React.Fragment>
            <PiCaretDown size={24} />
            <div>View All</div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PiCaretUp size={24} />
            <div>View Less</div>
          </React.Fragment>
        )}
      </div>
    );
  }

  const containerClass = classNames({
    'w-full flex flex-wrap justify-start': true,
    'gap-6': isBig,
    'gap-4': !isBig,
  });

  return (
    <div className={containerClass} id={groupId}>
      {updatedVideos.map((video) => (
        <ThumbnailBlock
          groupId={groupId}
          video={video}
          selectedClip={selectedClip}
          setSelectedClip={setSelectedClip}
          deletingClips={deletingClips}
          deleteClip={deleteClip}
          clipSelections={clipSelections}
          isBig={isBig}
          isDemo={isDemo}
          handleDemoClips={handleDemoClips}
          key={video.elementId}
        />
      ))}

      {showMoreBtn}
    </div>
  );
};

export default ThumbnailsContainer;
