import { toast } from 'react-toastify';
import { isEmpty } from '../Utils';

import { getCheckInLink } from '../Utils';
import {
  trackCheckInSuccessNewAttendee,
  trackCheckInSuccessReturningAttendee,
} from '../analytics';
import { EMAILS } from '../constants';

import { sendEmail } from './api/emails';
import {
  checkinFaceMerge,
  getCheckinFaceByEmail,
  getCheckinFaceById,
} from './api/faces';
import { getCheckinFaceVideos, getCheckinFacePhotos } from './api/files';
import { uploadFile } from './uploadFileService';

export const checkIn = async ({
  eventId,
  faceData = {},
  photo = null,
  videos = null,
  faceId = null,
}) => {
  let parentElementId = faceId;

  // create face
  if (!isEmpty(faceData)) {
    const face = await checkinFaceMerge(eventId, faceData);
    parentElementId = face.elementId;
  }

  // save and upload face
  if (photo && parentElementId) {
    await uploadFile({
      file: photo.originFileObj || photo,
      eventId,
      parentElementId,
      isCheckin: true,
    }).catch(() => {
      toast.error(`${photo.name} file upload failed.`);
    });
  }

  // save and upload UGC
  if (!isEmpty(videos)) {
    await Promise.all(
      videos.map((video) => {
        return new Promise((resolve, reject) => {
          return uploadFile({
            file: video.originFileObj || video,
            eventId,
            parentElementId: faceId,
            fileKind: 'video-footage',
            isCheckin: true,
          })
            .then(resolve)
            .catch((err) => {
              toast.error(`${video.name} file upload failed.`);
              reject(err);
            });
        });
      }),
    );
  }
};

export const checkInFace = async (event, email) => {
  // skip the checkin process if no event or email
  if (!event || !email) {
    return null;
  }

  // check if face exists
  let face = await getCheckinFaceByEmail(event.eventId, email);

  // if face doesn't exist – create new face & send welcome email
  if (isEmpty(face)) {
    face = await checkinFaceMerge(event.eventId, {
      email,
      name: email.split('@')[0],
    });

    const emailData = {
      recipientEmail: email,
      templateId:
        event.type === 'brand'
          ? EMAILS.CHECK_IN_BRAND
          : EMAILS.CHECK_IN_VIDEOGRAPHER,
      dynamicTemplateData: {
        event_name: event.name,
        share_url: getCheckInLink(event.eventId),
      },
    };

    await sendEmail(emailData);

    trackCheckInSuccessNewAttendee(event);
  } else {
    trackCheckInSuccessReturningAttendee(event);
  }

  return face;
};

export const getFaceWithFiles = async (eventId, face, refetchFace) => {
  let checkinFace = { ...face };

  if (refetchFace)
    checkinFace = await getCheckinFaceByEmail(eventId, face.email);

  const videos = await getCheckinFaceVideos(eventId, face.elementId);
  const photos = await getCheckinFacePhotos(eventId, face.elementId);

  return { ...checkinFace, videos, photos };
};

export const getFaceWithFilesById = async (eventId, faceId) => {
  let checkinFace = await getCheckinFaceById(faceId);

  if (!checkinFace) return null;

  const videos = await getCheckinFaceVideos(eventId, faceId);
  const photos = await getCheckinFacePhotos(eventId, faceId);

  return { ...checkinFace, videos, photos };
};
