import ReactGA from 'react-ga4';

export const trackCheckInSuccessNewAttendee = (event) => {
  ReactGA.event({
    category: 'Attendee',
    action: 'check_in_success_new_attendee',
    label: `${event.eventId} – ${event.name} (${event.type})`,
  });
};

export const trackCheckInSuccessReturningAttendee = (event) => {
  ReactGA.event({
    category: 'Attendee',
    action: `check_in_success_returning_attendee`,
    label: `${event.eventId} – ${event.name} (${event.type})`,
  });
};
