import React from 'react';
import { Link } from 'react-router-dom';

import {
  getPluralPhrase,
  getCheckInLink,
  getShareLink,
} from '../../../../Utils';

import {
  trackDownloadIndividualSamples,
  trackPeopleClickCheckInLink,
} from '../../../../analytics';

import { ZIP_URL } from '../../../../constants';

export const getPeopleText = ({
  people,
  noPeople,
  isUploading,
  isDemo,
  eventId,
  handleUploadMore,
  demoData,
}) => {
  const heading = 'Check-in people';
  let subheading = (
    <>
      <b>Tell our AI who to look for in your clips.</b>
      <br />
      Check-in people by uploading close-up images of their face. Our AI will
      then create personal highlight reels for the identified people.
      {!isUploading && (
        <div className="mt-3 italic">
          Not sure what to upload?{' '}
          <Link
            to={ZIP_URL.FIFA_FACES_ZIP}
            onClick={() => trackDownloadIndividualSamples('people')}
          >
            Try our samples
          </Link>
        </div>
      )}
    </>
  );

  if (isDemo) {
    subheading = (
      <>
        <b>
          Use the assets below by clicking on{' '}
          {demoData.isLimited ? 'all the people' : 'the people you want'} to
          load them into your project.
        </b>
        <br />
        Our AI will then create personal highlight reels for the identified
        people.
      </>
    );
  }

  const tipText = (
    <div>
      Tip: Share{' '}
      <Link
        className="text-white"
        to={getCheckInLink(eventId)}
        onClick={trackPeopleClickCheckInLink}
        target="_blank"
      >
        this link
      </Link>{' '}
      to allow people to self check-in.
    </div>
  );

  const uploadInfoPoints = [
    'One image per person (you can add more later)',
    'No hats, sunglasses or masks',
    'Should only include 1 person on the photo',
    'Should be taken as close as possible to include head and shoulders only',
    'Face should be clearly shown and not blurry',
    'Use high resolution image with good lighting',
  ];

  const summaryInfoPoints = [
    "Toggle the 'Details' switch to see each person's email address, Instagram, and custom field (if applicable)",
    'Click on the person to update their details or upload more photos',
    "Move your cursor over a person and click the 'X' button to delete it",
    "Click 'Check-In More People' to add new people at any time",
  ];

  // no people or people are uploading
  if (noPeople || isUploading) {
    return {
      heading,
      subheading,
      tipText,
      infoPoints: uploadInfoPoints,
    };
  }

  // people are uploaded
  return {
    heading,
    subheading: (
      <>
        {getPluralPhrase(people, 'person')} currently checked in.{' '}
        {!isDemo && (
          <span
            className="text-primary-900 underline cursor-pointer hover:opacity-80"
            onClick={() => handleUploadMore('Top link')}
          >
            Check-in more people
          </span>
        )}
      </>
    ),
    tipText,
    infoPoints: summaryInfoPoints,
    generatedText: (
      <div>
        The people below already have their personalised videos generated. Their
        videos will not be re-generated.
        {!isDemo && (
          <>
            {' '}
            <Link to={getShareLink(eventId)} target="_blank">
              View their videos
            </Link>
            .
          </>
        )}
      </div>
    ),
  };
};
