import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { trackPaymentSuccess, trackPaymentError } from '../../analytics';
import PageSpinner from '../../components/pageSpinner';

import { URL, STRIPE_PRODUCTS } from '../../constants';

import { retrieveSession } from '../../services/api/stripe';

const PaymentRedirect = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get('type');
  const sessionId = queryParameters.get('sessionId');

  const navigate = useNavigate();

  useEffect(() => {
    const getSession = async () => {
      try {
        const session = await retrieveSession(sessionId);

        if (type === STRIPE_PRODUCTS.REMOVE_WATERMARK) {
          const eventId = session.client_reference_id;
          trackPaymentSuccess();
          navigate(`/${eventId}${URL.VB_SHARE}?payment_success=true`);
        }
      } catch (error) {
        trackPaymentError();
      }
    };

    getSession();
  }, [navigate, sessionId, type]);

  return <PageSpinner isLoading noTips isFullPage />;
};

export default PaymentRedirect;
