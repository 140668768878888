import React, { useState, useEffect } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  isEmpty,
  canAccessEvent,
  canEditEvent,
  getEventImg,
  getBase64,
  getVideoBuilderLink,
} from '../../Utils';

import ContentHeader from '../../components/contentHeader';
import FormFeedback from '../../components/form/formFeedback';
import ErrorModal from '../../components/modals/errorModal';
import PageSpinner from '../../components/pageSpinner';
import SubmitBtn from '../../components/submitBtn';

import {
  URL,
  EVENT_TYPE_VIDEOGRAPHER,
  EVENT_TYPE_BRAND,
  REC_WILDCARDS,
} from '../../constants';

import { mergeEvent, getUserEvents, getEvent } from '../../services/api/events';

import { useEventStore } from '../../stores/event';
import { useUserStore } from '../../stores/user';
import withRouter from '../../withRouter';

import Error from '../General/error';

import EventCheckInSection from './components/eventCheckInSection';
import EventDetailsSection from './components/eventDetailsSection';
import EventShareSection from './components/eventShareSection';

import { validateForm } from './eventBuilderValidations';

const EventBuilder = () => {
  const user = useUserStore((state) => state.user);

  const queryParameters = new URLSearchParams(window.location.search);
  const eventId = queryParameters.get('eventId');

  const setEventId = useEventStore((state) => state.setEventId);

  const [openedEvent, setOpenedEvent] = useState({});
  const [eventName, setEventName] = useState('Untitled');
  const [type, setType] = useState(EVENT_TYPE_VIDEOGRAPHER);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [orientation, setOrientation] = useState(null);
  const [wildcards, setWildcards] = useState(REC_WILDCARDS);
  const [hashtag, setHashtag] = useState('');
  const [hashtagArray, setHashtagArray] = useState([]);
  const [caption, setCaption] = useState('');
  const [customFieldLabel, setCustomFieldLabel] = useState('');

  const [bannerFile, setBannerFile] = useState(null);
  const [bannerUrl, setBannerUrl] = useState('');
  const [logoFile, setLogoFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');

  const [errors, setErrors] = useState({});

  const [hasAccess, setHasAccess] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();

  const isBrand = type === 'brand';
  const isEditMode = !isEmpty(eventId);

  useEffect(() => {
    if (!isEditMode) {
      setIsLoading(true);
      onSubmit('');
    }
  }, [isEditMode]);

  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (isEditMode) {
        // if editing event – fetch all event data to fill the form
        const event = await getEvent(eventId);

        if (!event) setOpenedEvent(null);
        else if (!canAccessEvent(user, event)) setHasAccess(false);
        else {
          setOpenedEvent(event);
          setEventName(event.name ?? '');
          setType(event.type ?? null);
          setStartDate(event.startDate?.slice(0, 10) ?? '');
          setEndDate(event.endDate?.slice(0, 10) ?? '');
          setOrientation(event.videoMode ?? null);
          setWildcards(event.wildcards ?? REC_WILDCARDS);
          setCaption(event.caption ?? '');
          setHashtag(getHashtagText(event.hashTag ?? []));
          setHashtagArray(event.hashTag ?? []);
          setCustomFieldLabel(event.customFieldLabel ?? '');
          setBannerUrl(getEventImg('banner', event.banner));
          setLogoUrl(getEventImg('logo', event.logo));
        }

        setIsLoading(false);
      }
    };

    setEventId(eventId);
    onMount();
  }, [eventId]);

  const getHashtagText = (hashtagArray) => {
    return '#' + hashtagArray.join(' #');
  };

  const changeType = (value) => {
    setType(value);
    if ((value === EVENT_TYPE_BRAND) & isEmpty(wildcards))
      setWildcards(REC_WILDCARDS);
  };

  const onChange = (e, setValue) => {
    let value = e.target.value;

    if (e.target.name === 'hashtag') {
      const hashtagArray = value
        .split(/[\s#,.]+/)
        .filter((tag, index) => tag !== '' || index !== 0);

      value = getHashtagText(hashtagArray);

      setHashtagArray(hashtagArray);
    }

    setValue(value);

    setErrors({});
  };

  const onChangeImg = (file, isBanner) => {
    getBase64(file, (url) => {
      if (isBanner) setBannerUrl(url);
      else setLogoUrl(url);
    });

    if (isBanner) setBannerFile(file);
    else setLogoFile(file);

    setErrors({});
  };

  const onSubmit = async (e) => {
    if (e) e.preventDefault();

    const errors = await validateForm(isEditMode, {
      type,
      eventName,
      startDate,
      endDate,
      orientation,
      wildcards,
    });

    if (!isEmpty(errors) && isEditMode) {
      setErrors(errors);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      toast.error('Some data is missing or invalid');
    } else {
      setIsSaving(true);
      const formData = new FormData();

      const hasCustomBanner = !!bannerFile;
      const hasCustomLogo = !!logoFile;

      if (isEditMode) {
        formData.append('eventId', openedEvent.eventId);
        formData.append('created', openedEvent.created);
        formData.append('stage', openedEvent.stage);
      }

      if (orientation) formData.append('videoMode', orientation);
      else if (openedEvent.videoMode)
        formData.append('videoMode', openedEvent.videoMode);

      if (isBrand) {
        if (startDate) formData.append('startDate', startDate);
        if (endDate) formData.append('endDate', endDate);

        wildcards.forEach((w) => {
          formData.append('wildcards[]', parseFloat(w));
        });
      }

      formData.append('name', eventName);
      formData.append('userId', user.session.userId);
      formData.append('type', type);
      formData.append('caption', caption);
      hashtagArray.forEach((ht) => {
        formData.append('hashTag[]', ht);
      });
      formData.append('customFieldLabel', customFieldLabel);

      formData.append('hasCustomBanner', hasCustomBanner);
      if (hasCustomBanner) formData.append('banner', bannerFile);

      formData.append('hasCustomLogo', hasCustomLogo);
      if (hasCustomLogo) formData.append('logo', logoFile);

      mergeEvent(formData)
        .then(async (event) => {
          await getUserEvents(user.session.userId);
          setIsSaving(false);

          // go to edit if new brand event
          if (isBrand && !isEditMode)
            navigate(`${URL.DASH_EVENT_BUILDER}?eventId=${event.eventId}`);
          else {
            setIsLoading(false);
            navigate(getVideoBuilderLink(event));
          }
        })
        .catch(() => {
          setIsSaving(false);
          toast.error('Something went wrong. Try again later');
        });

      // session storage cleanup
      sessionStorage.removeItem('attendees');
    }
  };

  useEffect(() => {
    // session storage cleanup
    sessionStorage.removeItem('attendees');
  }, []);

  if (!openedEvent) return <Error code={404} />;
  else if (!hasAccess) return <Error code={403} />;

  const renderEditFields = () => {
    return (
      <div
        className={`flex flex-col gap-6 ${
          isBrand ? 'sm:flex-row sm:gap-10' : ''
        }`}
      >
        {isBrand && (
          <EventDetailsSection
            startDate={startDate}
            endDate={endDate}
            onChangeStartDate={(e) => onChange(e, setStartDate)}
            onChangeEndDate={(e) => onChange(e, setEndDate)}
            orientation={orientation}
            setOrientation={setOrientation}
            wildcards={wildcards}
            setWildcards={setWildcards}
            errors={errors}
          />
        )}

        <div
          className={`${isBrand ? 'w-full sm:w-1/2' : ''} flex flex-col gap-6 `}
        >
          <EventCheckInSection
            bannerUrl={bannerUrl}
            logoUrl={logoUrl}
            customFieldLabel={customFieldLabel}
            onChangeImg={onChangeImg}
            onChangeCustomFieldLabel={(e) => onChange(e, setCustomFieldLabel)}
            isBrand={isBrand}
          />

          <EventShareSection
            caption={caption}
            hashtag={hashtag}
            onChangeCaption={(e) => onChange(e, setCaption)}
            onChangeHashtag={(e) => onChange(e, setHashtag)}
          />
        </div>
      </div>
    );
  };

  return (
    <PageSpinner isLoading={isLoading} isPageContainer containerClass="gap-6">
      <ContentHeader title={`${isEditMode ? 'Edit' : 'Create'} Project`} />

      <Form onSubmit={onSubmit} noValidate>
        <fieldset className="flex flex-col gap-6" disabled={isSaving}>
          <FloatingLabel label="Project name*">
            <Form.Control
              type="text"
              name="eventName"
              value={eventName}
              onChange={(e) => onChange(e, setEventName)}
              placeholder="Project name"
              isInvalid={errors.eventName}
              required
            />
            <FormFeedback error={errors.eventName} />
          </FloatingLabel>

          {/* temporary removed  */}
          {/* <EventTypeSelector
            type={type}
            changeType={changeType}
            error={errors.type}
          /> */}

          {isEditMode && renderEditFields()}

          <SubmitBtn
            title={isEditMode ? 'Update' : 'Create'}
            isProcessing={isSaving}
          />
        </fieldset>
      </Form>

      <ErrorModal
        show={!canEditEvent(openedEvent)}
        heading="Sorry, this project cannot be edited"
        subheading="You may not have access to edit this project or it has already started."
      />
    </PageSpinner>
  );
};

export default withRouter(EventBuilder);
