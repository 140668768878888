import React from 'react';
import classNames from 'classnames';

import { Spinner, Button } from '.';

const SubmitBtn = (props) => {
  const { title, type, onClick, isProcessing, isDisabled, className } = props;

  return (
    <Button
      type={type ?? 'submit'}
      className={classNames('relative', className ?? '')}
      onClick={onClick}
      disabled={isProcessing || isDisabled}
    >
      <div
        className={`absolute transition-all duration-500 ${!isProcessing ? 'opacity-100' : 'opacity-0'}`}
      >
        {title}
      </div>

      <Spinner
        size="lg"
        color="white"
        className={`absolute transition-all duration-500 ${isProcessing ? 'opacity-100' : 'opacity-0'}`}
      />
    </Button>
  );
};

export default SubmitBtn;
