import React, { FC, useMemo, useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { Collapse } from 'react-bootstrap';
import {
  PiCaretDown,
  PiCaretRight,
  PiQuestion,
  PiVideo,
  PiFileZip,
  PiCalendarBlank,
} from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from '../../Utils';
import {
  trackUGModalOpen,
  trackOnboardingModalOpen,
  trackQuickstartPackDownload,
  trackSampleProjectOpen,
} from '../../analytics';

import OnboardingVideoModal from '../../components/modals/onboardingVideoModal';
import UserGuidingModal from '../../components/modals/userGuidingModal';
import PageContainer from '../../components/pageContainer';

import { URL, DEMO_ID, EVENT_TYPE_DEMO, ZIP_URL } from '../../constants';

import { useEventStore } from '../../stores/event';
import { useUserStore } from '../../stores/user';
import { UserEvents } from '../../types';

import { demoFifa } from '../VideoBuilder/components/demoData';

import CreateEventBtn from './createEventBtn';
import EventBlock from './eventBlock';

const Home: FC = () => {
  const user = useUserStore((state) => state.user);
  const userEvents: UserEvents[] = useEventStore((state) => state.userEvents);

  const [groupCollapseStates, setGroupCollapseStates] = useState<{
    [id: string]: boolean;
  }>({ 'completed-events': true, 'learning-materials': true });

  const [showOnboardingVideo, setShowOnboardingVideo] = useState(false);
  const [showUGModal, setShowUGModal] = useState(false);

  const navigate = useNavigate();

  const { isNewUser, recentEvents, completedEvents } = useMemo(() => {
    let isNewUser = false;
    const recentEvents: UserEvents[] = [];
    const completedEvents: UserEvents[] = [];

    userEvents.forEach((event) => {
      if (event.type !== EVENT_TYPE_DEMO) {
        if (event.stage === 'generation') completedEvents.push(event);
        else recentEvents.push(event);
      }
    });

    if (!completedEvents.length && !recentEvents.length) isNewUser = true;

    return { isNewUser, recentEvents, completedEvents };
  }, [userEvents]);

  useEffect(() => {
    if (isNewUser) setShowUGModal(true);
  }, [isNewUser]);

  //  toggle the collapse state of each group
  const toggleGroupCollapse = (groupId: string) => {
    const groupCollapseStatesTemp: { [id: string]: boolean } = {
      ...groupCollapseStates,
    };

    groupCollapseStatesTemp[groupId] = !groupCollapseStatesTemp[groupId];

    setGroupCollapseStates(groupCollapseStatesTemp);
  };

  const iconClass = 'w-8 h-8 sm:w-10 sm:h-10';

  const learningBlocks = [
    {
      image:
        'https://t3.ftcdn.net/jpg/05/24/43/88/360_F_524438877_PZFzwc5OWJ3MTWQVFfHKwu1DRVMaSgPx.jpg',
      icon: <PiQuestion className={iconClass} />,
      label: 'How does it work?',
      onClick: () => {
        setShowUGModal(true);
        trackUGModalOpen();
      },
    },
    {
      image:
        'https://cdn.pixabay.com/photo/2022/09/21/17/02/blue-background-7470781_640.jpg',
      icon: <PiVideo className={iconClass} />,
      label: 'Watch onboarding video',
      onClick: () => {
        setShowOnboardingVideo(true);
        trackOnboardingModalOpen();
      },
    },
    {
      image:
        'https://st4.depositphotos.com/1741969/29457/i/450/depositphotos_294571810-stock-photo-blackground-of-abstract-glitter-lights.jpg',
      icon: <PiFileZip className={iconClass} />,
      label: 'Download the quickstart pack',
      onClick: () => {
        window.location.href = ZIP_URL.QUICKSTART;
        trackQuickstartPackDownload('Learning materials');
      },
    },
    {
      image: '/static/media/afl-event-thumbnail.79e329cc90e61b383f77.jpeg',
      icon: <PiCalendarBlank className={iconClass} />,
      label: 'Play with Sample Project',
      onClick: () => {
        const demoData = localStorage.getItem('demoData');
        if (!demoData)
          localStorage.setItem('demoData', JSON.stringify(demoFifa));

        trackSampleProjectOpen();

        navigate(`/${DEMO_ID.FIFA}${URL.VIDEO_BUILDER}`);
      },
    },
  ];

  const renderLearningMaterials = (isCollapse: boolean) => {
    const isShow = groupCollapseStates['learning-materials'] || !isCollapse;

    return (
      <div className="w-full">
        {isCollapse && (
          <Collapse in={!isShow}>
            <div id="collapse-wrapper">
              <div className="flex justify-center">
                <button
                  className="px-4 py-2 rounded-md bg-grey-100 hover:bg-grey-200"
                  onClick={() => toggleGroupCollapse('learning-materials')}
                >
                  Learn More
                </button>
              </div>
            </div>
          </Collapse>
        )}

        <Collapse in={isShow}>
          <div id="collapse-wrapper">
            <div className="w-full flex flex-col gap-4">
              <h5 className="m-0">Don't know where to start?</h5>

              <div className="flex flex-wrap gap-4 sm:gap-6">
                {learningBlocks.map((block) => {
                  const thumbnailClass =
                    'w-[var(--learning-block-width)] h-[var(--learning-block-height)] rounded-md';

                  return (
                    <div
                      className="group relative cursor-pointer"
                      onClick={block.onClick}
                      key={block.label}
                    >
                      <img
                        className={`${thumbnailClass} absolute object-cover`}
                        src={block.image}
                        alt="Learning block"
                      />

                      <div
                        className={`${thumbnailClass} absolute bg-black-overlay-500 transition-all group-hover:bg-black`}
                      />

                      <div
                        className={`${thumbnailClass} relative flex flex-col items-center justify-center text-center p-3 sm:p-4 gap-2 text-white transition-all group-hover:scale-110`}
                      >
                        {block.icon}

                        <div>{block.label}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    );
  };

  const renderEventsContainer = (
    heading: string,
    events: UserEvents[],
    type?: string,
  ) => {
    if (isEmpty(events)) return null;

    const isShow = !!type ? groupCollapseStates[type] : true;

    return (
      <div className="w-full flex flex-col gap-4">
        {!!type ? (
          <div
            className="flex items-center p-2 gap-2 rounded-md cursor-pointer hover:bg-grey-100"
            onClick={() => toggleGroupCollapse(type)}
          >
            <div className="text-[1.1rem] leading-none">
              {isShow ? <PiCaretDown /> : <PiCaretRight />}
            </div>
            <h5 className="m-0">{heading}</h5>
          </div>
        ) : (
          <h5 className="m-0">{heading}</h5>
        )}

        <Collapse in={isShow}>
          <div id="collapse-wrapper">
            <div className="flex flex-wrap gap-4 sm:gap-7">
              {events.map((event) => (
                <EventBlock event={event} key={event.eventId} />
              ))}
            </div>
          </div>
        </Collapse>
      </div>
    );
  };

  return (
    <PageContainer addClass="gap-8">
      {/* welcome box */}
      <div className="animated-box-purple w-full flex flex-col items-center justify-center text-center p-4 sm:p-6 gap-4 sm:gap-6 text-white rounded-md animate-running-5">
        <div className="text-xl sm:text-2xl font-bold uppercase">
          Welcome {isNewUser ? parse('to CrowdClip&reg;') : 'back'},{' '}
          {user.firstName ?? 'amazing human'}!
        </div>

        <div>
          Let's get started on a better way to generate hundreds of personalised
          videos for your events.
        </div>

        <CreateEventBtn />
      </div>

      {/* content */}
      {isNewUser ? (
        <React.Fragment>
          {renderLearningMaterials(false)}

          <h5 className="w-full m-0">Recent projects</h5>

          <span className="w-full text-grey-900 font-bold text-center">
            Your projects will appear here after creating one
          </span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {renderLearningMaterials(true)}

          {renderEventsContainer('Recent projects', recentEvents)}

          {renderEventsContainer(
            'Completed projects',
            completedEvents,
            'completed-events',
          )}
        </React.Fragment>
      )}

      <OnboardingVideoModal
        isNewUser={false}
        showOnboardingVideo={showOnboardingVideo}
        setShowOnboardingVideo={setShowOnboardingVideo}
      />

      <UserGuidingModal
        show={showUGModal}
        onHide={() => setShowUGModal(false)}
      />
    </PageContainer>
  );
};

export default Home;
