import config from '../../config';
import { getAccessTokenClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const accessTokenClient = () => getAccessTokenClient(baseUrl);

export const getTemplateFile = async (eventId) => {
  try {
    const response = await accessTokenClient().get(
      `api/files?eventId=${eventId}&fileType=video&fileKind=VideoTemplate`,
    );
    return response.data.files.slice(-1)[0];
  } catch (err) {
    throw new Error(`Error getting template file: ${err.message}`);
  }
};

export const getTemplateData = async (eventId) => {
  try {
    const response = await accessTokenClient().get(
      `api/video-template/current?eventId=${eventId}`,
    );

    let videoTemplate = response.data.videoTemplate;

    let requiredShots = [];
    videoTemplate?.shots.forEach((shot) => {
      if (shot.type === 'placeholder')
        requiredShots.push({
          shotIndex: shot.shotIndex,
          duration: shot.segment.durationMilliseconds / 1000,
        });
    });

    return {
      hasTemplate: true,
      videoTemplate,
      requiredShots,
    };
  } catch (err) {
    if (err.response?.status === 404) {
      return {
        hasTemplate: false,
        videoTemplate: null,
        requiredShots: [],
      };
    } else throw new Error(`Error getting a template: ${err.message}`);
  }
};
