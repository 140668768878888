import React, { useState } from 'react';
import { Form, InputGroup, FloatingLabel } from 'react-bootstrap';
import { PiEyeFill, PiEyeSlashFill } from 'react-icons/pi';
import FormFeedback from './formFeedback';

const PasswordField = (props) => {
  const { className, name, label, value, isInvalid, errMsg, onChange } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form.Group className={className ?? ''}>
      <InputGroup>
        <FloatingLabel label={label}>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            name={name}
            value={value}
            onChange={onChange}
            placeholder="******"
            isInvalid={isInvalid}
            required
          />
        </FloatingLabel>

        <InputGroup.Text
          className="w-[var(--form-el-height)] h-[var(--form-el-height)] justify-center"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <PiEyeSlashFill size="1.5rem" />
          ) : (
            <PiEyeFill size="1.5rem" />
          )}
        </InputGroup.Text>
      </InputGroup>

      <FormFeedback error={errMsg} />
    </Form.Group>
  );
};

export default PasswordField;
