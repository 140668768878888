import React from 'react-bootstrap';
import {
  PiPlayFill,
  PiEnvelopeFill,
  PiDownloadSimpleFill,
  PiShareFatFill,
} from 'react-icons/pi';

const StatsBlock = (props) => {
  const { stats } = props;

  const avg = () => {
    return window.innerWidth >= 640 ? 'Average' : 'Avg.';
  };

  const data = [
    {
      mainData: {
        icon: <PiEnvelopeFill />,
        text: 'Attendees emailed',
        num: stats.emailedPct,
      },
      otherData: [
        { text: 'Total emails sent', num: stats.emailedTotal },
        { text: `${avg()} emails per attendee`, num: stats.emailedAverage },
      ],
    },
    {
      mainData: {
        icon: <PiPlayFill />,
        text: 'Videos played',
        num: stats.viewedPct,
      },
      otherData: [
        { text: 'Total video plays', num: stats.viewedTotal },
        { text: `${avg()} plays per video`, num: stats.viewedAverage },
      ],
    },
    {
      mainData: {
        icon: <PiDownloadSimpleFill />,
        text: 'Videos downloaded',
        num: stats.downloadedPct,
      },
      otherData: [
        { text: 'Total video downloads', num: stats.downloadedTotal },
        { text: `${avg()} downloads per video`, num: stats.downloadedAverage },
      ],
    },
    {
      mainData: {
        icon: <PiShareFatFill />,
        text: 'Videos shared',
        num: stats.sharedPct,
      },
      otherData: [
        { text: 'Total video shares', num: stats.sharedTotal },
        { text: `${avg()} shares per video`, num: stats.sharedAverage },
      ],
    },
  ];

  return (
    <div className="flex flex-wrap gap-4">
      {data.map((metric) => (
        <div className="w-[calc((var(--page-width)_-_1rem)_/_2)] sm:w-[calc((var(--page-width)_-_(1rem_*_3))_/_4)]">
          <div className="flex p-3 sm:p-4 gap-3 sm:gap-4 items-center rounded-t-md border-1 border-black border-solid">
            <div className="flex items-center justify-center p-2 sm:p-3 text-lg sm:text-[1.25rem] text-white bg-black rounded-full">
              {metric.mainData.icon}
            </div>
            <div>
              <div className="text-md sm:text-lg font-bold">
                {metric.mainData.num ?? 0}%
              </div>
              <div className="text-xs sm:text-sm">{metric.mainData.text}</div>
            </div>
          </div>

          <div className="flex flex-col p-3 sm:p-4 gap-1 text-white bg-black rounded-b-md">
            {metric.otherData.map((el) => (
              <div className="flex items-center justify-between gap-1">
                <div className="text-xs sm:text-sm">{el.text}</div>
                <div className="text-sm sm:text-md font-bold">
                  {el.num ?? 0}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatsBlock;
