import config from '../../config';
import { getAccessTokenClient, getCheckinClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const accessTokenClient = () => getAccessTokenClient(baseUrl);
const checkinClient = () => getCheckinClient(baseUrl);

export const privateFaceMerge = async (eventId, faceData) => {
  try {
    const response = await accessTokenClient().put('api/face', {
      eventId,
      ...faceData,
    });
    console.log('create face success!', response);
    return response.data.face;
  } catch (err) {
    throw new Error(`Error with creating face: ${err.message}`);
  }
};

export const checkinFaceMerge = async (eventId, faceData) => {
  try {
    const response = await checkinClient().put('api/face', {
      eventId,
      ...faceData,
    });
    console.log('merge face success!', response);
    return response.data.face;
  } catch (err) {
    throw new Error(`Error with creating face: ${err.message}`);
  }
};

export const deleteFace = async (elementId) => {
  try {
    await accessTokenClient().delete(`api/face/${elementId}`);
  } catch (err) {
    throw new Error(`Error deleting face: ${err.message}`);
  }
};

export const getUploadedFaces = async (eventId) => {
  try {
    const response = await accessTokenClient().get(
      `api/faces?eventId=${eventId}`,
    );
    return response.data.faces;
  } catch (err) {
    throw new Error('Error fetching uploaded faces', err);
  }
};

export const getCheckinFaceByEmail = async (eventId, email) => {
  try {
    const response = await checkinClient().get(
      `api/event/${eventId}/face?email=${email}`,
    );
    return response.data;
  } catch (err) {
    if (err.response?.status === 404) return null;
    throw new Error(`Error with getting checkin face: ${err.message}`);
  }
};

export const getCheckinFaceById = async (faceId) => {
  try {
    const response = await checkinClient().get(`api/faces/${faceId}`);
    return response.data.face;
  } catch (err) {
    if (err.response?.status === 404) return null;
    throw new Error(`Error with getting checkin face: ${err.message}`);
  }
};

export const getFaceResults = async (eventId) => {
  try {
    const response = await accessTokenClient().get(
      `api/face-results?eventId=${eventId}`,
    );
    return response.data;
  } catch (err) {
    if (err.response.data.message === 'There are no video templates') {
      return null;
    } else
      throw new Error(
        `Error getting face results: ${
          err.response.data.message || err.message
        }`,
      );
  }
};
