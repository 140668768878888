import React, { useState, ChangeEvent, FormEvent, useEffect, FC } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { validateEmail } from '../../Utils';
import { sendVerificationEmail } from '../../UtilsEmail';

import { trackEnterEmailClickContinue } from '../../analytics';
import EmailField from '../../components/form/emailField';
import SubmitBtn from '../../components/submitBtn';

import { URL } from '../../constants';

import { checkEmailAvailability, createUser } from '../../services/api/auth';

import { useUserStore } from '../../stores/user';
import { EmailAvailability, EmailStatus, UserState } from '../../types';

import OnboardingPage from './onboardingPage';

const EnterEmail: FC = () => {
  const [email, setEmail] = useState<string>(
    sessionStorage.getItem('email') || '',
  );
  const [emailStatus, setEmailStatus] = useState<EmailStatus>('');
  const [emailErr, setEmailErr] = useState<string>('');
  const [allowEmailEnter, setAllowEmailEnter] = useState<boolean>(false);

  const setUser = useUserStore((state: UserState) => state.setUser);
  const navigate = useNavigate();

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailStatus('');
    setEmailErr('');
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const emailErr = validateEmail(email);

    if (emailErr) {
      setEmailErr(emailErr);
    } else {
      setEmailStatus('loading');

      try {
        const availability: EmailAvailability =
          await checkEmailAvailability(email);

        sessionStorage.setItem('email', email);

        trackEnterEmailClickContinue(availability);

        // accounts doesn't exist
        if (availability === 'available') {
          const res = await createUser({ email });
          setUser(res.user);

          const isSuccess = await sendVerificationEmail(email);
          if (isSuccess) navigate(URL.EMAIL_VERIFICATION);
        }

        // account exists and email is verified
        else if (availability === 'existing') {
          navigate(URL.LOGIN);
        }

        // account exists, but email is NOT verified
        else if (availability === 'pending') {
          const isSuccess = await sendVerificationEmail(email);
          if (isSuccess) navigate(URL.EMAIL_VERIFICATION);
        }

        // email is blocked for some reason
        else if (availability === 'unavailable') {
          setEmailStatus(availability);
          setEmailErr(
            'Sorry, this email address is unavailable. Contact us for assistance or try a different email address.',
          );
        } else {
          setEmailStatus(availability);
        }
      } catch (err) {
        toast.error('Something went wrong. Try again later');
        console.error('Failed check email availability:', err);
        setEmailStatus('');
      }
    }
  };

  useEffect(() => {
    const isUnavailableOrBlankStatus: boolean =
      emailStatus === 'unavailable' || emailStatus === '';
    setAllowEmailEnter(isUnavailableOrBlankStatus);
  }, [emailStatus]);

  return (
    <OnboardingPage heading="Welcome to CrowdClip®">
      <Form noValidate onSubmit={onSubmit}>
        <fieldset className="gap-4" disabled={!allowEmailEnter}>
          <EmailField
            value={email}
            isInvalid={!!emailErr}
            errMsg={emailErr || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeEmail(e)}
            required
          />

          <SubmitBtn
            title="Let's Go"
            isProcessing={emailStatus === 'loading'}
            className="w-full"
          />
        </fieldset>
      </Form>
    </OnboardingPage>
  );
};

export default EnterEmail;
