import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

import FormFeedback from '../../../components/form/formFeedback';

const EventDatesSelector = (props) => {
  const { startDate, endDate, onChangeStartDate, onChangeEndDate, errors } =
    props;

  return (
    <React.Fragment>
      <div className="flex flex-col gap-2">
        <h5>Start Date*</h5>

        <div className="text-sm italic">
          You won't be able to edit the project after it starts.
        </div>

        <FloatingLabel label="Start Date*">
          <Form.Control
            type="date"
            name="startDate"
            value={startDate}
            onChange={onChangeStartDate}
            placeholder="dd/mm/yyyy"
            isInvalid={errors.startDate}
            required
          />
          <FormFeedback error={errors.startDate} />
        </FloatingLabel>
      </div>

      <div className="flex flex-col gap-2">
        <h5>End Date*</h5>

        <div className="text-sm italic">
          Attendees won't be able to check in after the project ends.
        </div>

        <FloatingLabel label="End Date*">
          <Form.Control
            type="date"
            name="endDate"
            value={endDate}
            onChange={onChangeEndDate}
            placeholder="dd/mm/yyyy"
            isInvalid={errors.endDate}
            required
          />
          <FormFeedback error={errors.endDate} />
        </FloatingLabel>
      </div>
    </React.Fragment>
  );
};

export default EventDatesSelector;
