import React, { useState } from 'react';
import classNames from 'classnames';
import { Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import {
  trackUploadOrientationChoiceAccept,
  trackUploadOrientationReminderAccept,
  trackUploadOrientationModalReject,
} from '../../../analytics';

import { Spinner, Button } from '../../../components';
import { setEventMode } from '../../../services/api/events';
import { useEventStore } from '../../../stores/event';

const VideoModeModal = (props) => {
  const { show, onHide, handleUpload, rejectUpload, onShowShot, type } = props;

  const eventId = useEventStore((state) => state.eventId);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const currentEvent = useEventStore((state) => state.currentEvent);
  const videoMode = currentEvent?.videoMode;

  const [isSaved, setIsSaved] = useState(false);
  const [isSettingMode, setIsSettingMode] = useState(false);

  const analyticsPage = type.replace(/-/g, '_');

  // accept upload & hide modal
  const handleAccept = (selectedMode) => {
    handleUpload && handleUpload();
    onHide();

    if (type === 'hl-reel') onShowShot();

    if (videoMode) trackUploadOrientationReminderAccept(analyticsPage, isSaved);
    else trackUploadOrientationChoiceAccept(analyticsPage, selectedMode);

    // save in localStorage that reminder is saved (won't show again)
    if (videoMode && isSaved) {
      let videoModeSaved =
        JSON.parse(localStorage.getItem('videoModeSaved')) ?? {};
      if (typeof videoModeSaved !== 'object') {
        videoModeSaved = {};
      }
      videoModeSaved[eventId] = true;
      localStorage.setItem('videoModeSaved', JSON.stringify(videoModeSaved));
    }
  };

  // reject upload & hide modal
  const handleReject = () => {
    rejectUpload();
    onHide();

    trackUploadOrientationModalReject(analyticsPage, !!videoMode);
  };

  // if mode not selected yet – save it in localStorage
  const onClickMode = async (mode) => {
    if (!videoMode) {
      setIsSettingMode(true);

      const data = { eventId, videoMode: mode };

      const { success } = await setEventMode(data);
      await updateCurrentEvent();

      setIsSettingMode(false);

      if (success) {
        handleAccept(mode);
      } else {
        toast.error('Something went wrong. Try again later');
        handleReject();
      }
    }
  };

  const renderModeIcon = (orientation, isReminder) => {
    const iconClass = classNames({
      'bg-center bg-contain bg-no-repeat transition-all': true,
      'cursor-pointer hover:scale-110': !isReminder,
      'w-[var(--mode-icon-side-b)] h-[var(--mode-icon-side-a)] bg-[url("/src/assets/mode-portrait-box.png")]':
        orientation === 'portrait',
      'hover:bg-[url("/src/assets/mode-portrait-device.png")]':
        orientation === 'portrait' && !isReminder,
      'w-[var(--mode-icon-side-a)] h-[var(--mode-icon-side-b)] bg-[url("/src/assets/mode-landscape-box.png")]':
        orientation === 'landscape',
      'hover:bg-[url("/src/assets/mode-landscape-device.png")]':
        orientation === 'landscape' && !isReminder,
    });

    if (isReminder)
      return <div className={classNames('self-center', iconClass)} />;

    return (
      <div className="flex flex-col items-center gap-3">
        <div className="flex items-center justify-center h-[var(--mode-icon-side-a)]">
          <div className={iconClass} onClick={() => onClickMode(orientation)} />
        </div>

        {orientation === 'portrait' ? (
          <div className="text-center"> Instagram & Facebook Reels/Stories</div>
        ) : (
          <div className="text-center">YouTube, Vimeo, Websites</div>
        )}
      </div>
    );
  };

  return (
    <Modal show={show} onHide={handleReject} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {videoMode ? 'Reminder' : 'Select video orientation'}
        </Modal.Title>
      </Modal.Header>

      {videoMode ? (
        <Modal.Body className="flex flex-col gap-4">
          <div>
            Your video will be processed and optimised for <i>{videoMode}</i>.
            To confirm, click <b>Continue</b>.
          </div>

          {renderModeIcon(videoMode, true)}

          <Form.Check id="check-api-checkbox">
            <Form.Check.Input
              value={isSaved}
              onChange={(e) => setIsSaved(e.target.checked)}
            />
            <Form.Check.Label>Don't show again</Form.Check.Label>
          </Form.Check>

          <div className="flex flex-col sm:flex-row gap-4">
            <Button
              variant="pink"
              className="w-full sm:w-1/2"
              onClick={handleReject}
            >
              Cancel Upload
            </Button>
            <Button className="w-full sm:w-1/2" onClick={handleAccept}>
              Continue
            </Button>
          </div>

          <div className="text-sm italic">
            To change this selection contact us at{' '}
            <a href="mailto:hello@crowdclip.com">hello@crowdclip.com</a>.
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <div>
            Once selection is made, note that the highlight reel and clips will
            be converted to this orientation and cannot be changed.
          </div>

          {isSettingMode ? (
            <div className="h-[var(--mode-icon-side-a)] flex items-center justify-center">
              <Spinner customSize="w-20 h-20" />
            </div>
          ) : (
            <div className="flex items-center justify-center gap-6">
              {renderModeIcon('portrait')}
              {renderModeIcon('landscape')}
            </div>
          )}
        </Modal.Body>
      )}
    </Modal>
  );
};

export default VideoModeModal;
