import React from 'react';
import classNames from 'classnames';
import { PiPencil } from 'react-icons/pi';
import { Link } from 'react-router-dom';

import { getFileImg } from '../Utils';

import { Spinner } from '../components';
import SideIcon from '../components/icons/sideIcon';

import DemoAddOverlay from '../pages/VideoBuilder/components/demoAddOverlay';

const FaceCircle = (props) => {
  const {
    event,
    person,
    isBig,
    isShowDetails,
    isSpinner,
    onClick,
    onClickDelete,
    deletingFaces,
    isDemo = false,
    handleDemoPeople = () => {},
  } = props;

  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};

  const containerClass = classNames({
    'w-[var(--upload-el-width)]': isBig,
    'w-[var(--face-size)]': !isBig,
  });

  const circleClass = classNames({
    'rounded-full': true,
    'w-[var(--upload-el-width)] h-[var(--upload-el-width)]': isBig,
    'w-[var(--face-size)] h-[var(--face-size)]': !isBig,
  });

  const face = person.face;

  const faceImage = isSpinner ? (
    <Spinner customSize={circleClass} color="primary" />
  ) : (
    <img
      src={getFileImg(person.files[0])}
      className={`${circleClass} object-cover`}
      alt={`${face.name} Icon`}
    />
  );

  if (isShowDetails)
    return (
      <div className="group w-full relative">
        {onClickDelete && (
          <div className="w-full absolute">
            <SideIcon
              icon="delete"
              itemType="person"
              isProcessing={deletingFaces.includes(face.elementId)}
              onClick={(e) => onClickDelete(e, face)}
            />
          </div>
        )}

        <div
          className="group w-full flex items-center p-3 gap-2 sm:gap-4 rounded-md cursor-pointer hover:bg-grey-100"
          onClick={onClick}
        >
          <img
            src={getFileImg(person.files[0])}
            className="w-24 h-24 rounded-full object-cover"
            alt={`${face.name} Icon`}
          />

          <div>
            <h5 className="flex items-center mb-2 gap-1">
              <div>{face.name}</div>{' '}
              <PiPencil className="cursor-pointer hidden group-hover:block hover:fill-[url('#icon-primary-gradient')]" />
            </h5>

            <div className="break-all">
              <div>
                <b>Email</b>: {face.email ?? '—'}
              </div>
              <div>
                <b>Instagram</b>: {face.instagram ?? '—'}
              </div>
              {event.customFieldLabel && (
                <div>
                  <b>{event.customFieldLabel}</b>: {face.customFieldData ?? '—'}
                </div>
              )}
            </div>

            {person.videoLink && (
              <div className="italic">
                Watch generated video{' '}
                <Link
                  to={person.videoLink}
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                >
                  here
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );

  const blockClass = classNames({
    'group transition-transform duration-300': true,
    'cursor-pointer': !!onClick || isDemo,
    'hover:scale-110': !!onClick && !isDemo,
  });

  const handleOnClick = () => {
    if (isDemo) handleDemoPeople(face.elementId, true);
    else if (onClick) onClick();
  };

  const handleOnDelete = (e) => {
    e.stopPropagation();

    if (isDemo) handleDemoPeople(face.elementId, false);
    else onClickDelete(e, face);
  };

  return (
    <div className={containerClass} onClick={handleOnClick}>
      <div className={`${circleClass} ${blockClass}`}>
        {!!onClickDelete &&
          (!isDemo ||
            (!demoData.isLimited &&
              person.isSelected &&
              !person.isGenerated)) && (
            <div className={`${circleClass} absolute`}>
              <SideIcon
                icon="delete"
                itemType="person"
                isProcessing={deletingFaces?.includes(face.elementId)}
                onClick={handleOnDelete}
                hidePopover={isDemo}
              />
            </div>
          )}

        {isDemo && (
          <DemoAddOverlay
            element={person}
            addClass={`${circleClass} text-[1.25rem]`}
          />
        )}

        {faceImage}
      </div>

      <div className="mt-2 text-center whitespace-nowrap overflow-hidden text-ellipsis">
        {face.name}
      </div>
    </div>
  );
};

export default FaceCircle;
