import React from 'react';
import { Navigate } from 'react-router-dom';

import { URL } from './constants';
import { useUserStore } from './stores/user';

export const PrivateRoute = ({ children }) => {
  const accessToken = useUserStore((state) => state.accessToken);

  // if user is NOT authenticated
  if (!accessToken) {
    // save page URL in localStorage – to redirect back after login
    localStorage.setItem('previousPage', window.location.href);

    // redirect to Enter Email
    return <Navigate to={URL.ENTER_EMAIL} />;
  }

  return children;
};

export const PublicRoute = ({ children }) => {
  const accessToken = useUserStore((state) => state.accessToken);

  // if user is authenticated – redirect to Home
  if (accessToken) return <Navigate to={URL.HOME} />;

  return children;
};
