import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { isEmpty, getShareLink } from '../../../../Utils';

import {
  trackAnalysisClickUploadMoreClips,
  trackAnalysisOpenMAModal,
} from '../../../../analytics';

import FaceCircle from '../../../../components/faceCircle';
import PageSubheader from '../../../../components/pageSubheader';

import { URL } from '../../../../constants';

import MissingAssetsModal from './missingAssetsModal';

const PeopleBlocks = (props) => {
  const {
    event,
    completePeople,
    incompletePeople,
    generatedPeople,
    requiredShots,
    setOpenedPersonId,
    isDemo,
  } = props;

  const [showMAModal, setShowMAModal] = useState(false);

  const handleOpenMAModal = () => {
    setShowMAModal(true);

    trackAnalysisOpenMAModal();
  };

  return (
    <React.Fragment>
      {!isEmpty(completePeople) && (
        <div className="flex flex-row flex-wrap gap-[1.5vw]">
          {completePeople.map((person) => (
            <FaceCircle
              key={person.face.elementId}
              person={person}
              onClick={() => setOpenedPersonId(person.face.elementId)}
            />
          ))}
        </div>
      )}

      {!isEmpty(incompletePeople) && (
        <React.Fragment>
          <PageSubheader
            heading="Not enough clips"
            subheading={
              <div>
                The people below don't appear in enough clips to create a
                personalised highlight reel.
                {!isDemo && (
                  <>
                    {' '}
                    <Link
                      to={`/${event?.eventId}${URL.VB_CLIPS}`}
                      onClick={trackAnalysisClickUploadMoreClips}
                    >
                      Upload more clips
                    </Link>{' '}
                    or{' '}
                    <span
                      onClick={handleOpenMAModal}
                      className="underline cursor-pointer text-primary-900 hover:opacity-60"
                    >
                      email these people
                    </span>{' '}
                    to fix this issue.
                  </>
                )}
              </div>
            }
          />

          <div className="flex flex-row flex-wrap gap-[1.5vw]">
            {incompletePeople.map((person) => (
              <FaceCircle
                key={person.face.elementId}
                person={person}
                requiredShots={requiredShots}
                onClick={() => setOpenedPersonId(person.face.elementId)}
              />
            ))}
          </div>
        </React.Fragment>
      )}

      {!isEmpty(generatedPeople) && (
        <React.Fragment>
          <PageSubheader
            heading="Already generated"
            subheading={
              <div>
                The people below already have their personalised videos
                generated. Their videos will not be re-generated.{' '}
                {!isDemo && (
                  <>
                    <Link to={getShareLink(event?.eventId)} target="_blank">
                      View their videos
                    </Link>
                    .
                  </>
                )}
              </div>
            }
          />

          <div className="flex flex-row flex-wrap gap-[1.5vw]">
            {generatedPeople.map((person) => {
              const videoLink = getShareLink(
                event?.eventId,
                person.face.elementId,
              );

              return (
                <FaceCircle
                  key={person.face.elementId}
                  person={person}
                  onClick={() => window.open(videoLink, '_blank')}
                />
              );
            })}
          </div>
        </React.Fragment>
      )}

      <MissingAssetsModal
        show={showMAModal}
        onHide={() => setShowMAModal(false)}
        event={event}
        people={incompletePeople}
      />
    </React.Fragment>
  );
};

export default PeopleBlocks;
