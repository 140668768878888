import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DEMO_ID } from '../../../../constants';
import DemoAddOverlay from '../../components/demoAddOverlay';

const DemoComponent = (props) => {
  const { handleUploadStart } = props;

  const eventId = useParams()?.eventId;

  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  const demoThumbnailClass =
    'w-[90vw] sm:w-[25vw] h-[calc(90vw_*_9_/_16)] sm:h-[calc(25vw_*_9_/_16)] object-cover rounded-md';

  if (!isDemo) return null;

  return (
    <React.Fragment>
      <div
        className={`${demoThumbnailClass} relative self-center cursor-pointer`}
        onClick={handleUploadStart}
      >
        <DemoAddOverlay
          element={demoData.hlReel}
          addClass={`${demoThumbnailClass} text-[1.75rem]`}
        />

        <img
          src={demoData.hlReel.thumbnailUrl}
          alt="Demo Highlight Reel"
          className={demoThumbnailClass}
        />
      </div>
    </React.Fragment>
  );
};

export default DemoComponent;
