import React, { useState, useMemo } from 'react';
import { Form } from 'react-bootstrap';
// import VideoThumbnail from 'react-video-thumbnail';

import { isEmpty } from '../../../Utils';

// import { Alert } from '../../../components';
import DraggerSimplified from '../../../components/draggerSimplified';
import ConfirmModal from '../../../components/modals/confirmModal';
import ErrorModal from '../../../components/modals/errorModal';
import SubmitBtn from '../../../components/submitBtn';
import UploadInfoPoints from '../../../components/uploadInfoPoints';
// import { getWildcardTextMaximum } from '../../../components/wildcardText';

import { EMAILS, BRAND_CI_STEPS } from '../../../constants';

import { sendEmail } from '../../../services/api/emails';
import { checkIn } from '../../../services/checkInService';

import { getVideoText } from './checkinText';
import DetailsModal from './detailsModal';
import VideoBlock from './videoBlock';
import VideoPreviewModal from './videoPreviewModal';

// note: the stage is used for Brand UGC upload and Brand & Videographer missing video upload
// the logic of upload is the same, but text differentiates
// to differentiate the use cases – missing video receives the face, UGC – not

const VideoStage = (props) => {
  const { event, face, isMissingVideo, refetchFace, reqShots } = props;

  const [videoFiles, setVideoFiles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [isDetailsProvided, setIsDetailsProvided] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setShowConfirmModal(true);

    try {
      await checkIn({
        eventId: event.eventId,
        videos: videoFiles,
        faceId: face.elementId,
      });

      if (!isMissingVideo) {
        const emailData = {
          recipientEmail: face.email,
          templateId: EMAILS.UGC_RECEIVED,
          dynamicTemplateData: {
            event_name: event.name,
            share_url: window.location.href,
          },
        };
        await sendEmail(emailData);
      }
    } catch (err) {
      setError(err.message);
      setShowConfirmModal(false);
    } finally {
      setIsProcessing(false);
      setVideoFiles([]);
    }
  };

  const onHideConfirmModal = (showDetails) => {
    setShowConfirmModal(false);
    setVideoFiles([]);
    refetchFace && refetchFace();
    setIsDetailsProvided(false);

    showDetails && setShowDetailsModal(true);
  };

  const handleDetailsSteps = (step, msg) => {
    switch (step) {
      case BRAND_CI_STEPS.DETAILS_STARTED:
        setIsProcessing(true);
        break;

      case BRAND_CI_STEPS.DETAILS_ENDED:
        setIsProcessing(false);
        refetchFace();
        break;

      case BRAND_CI_STEPS.PHOTO_STARTED:
        setIsProcessing(true);
        setShowConfirmModal(true);
        break;

      case BRAND_CI_STEPS.PHOTO_ENDED:
        setIsProcessing(false);
        setIsDetailsProvided(true);
        break;

      case BRAND_CI_STEPS.NO_PHOTO:
        setShowConfirmModal(true);
        setIsDetailsProvided(true);
        break;

      case BRAND_CI_STEPS.ERROR:
        setIsProcessing(false);
        setError(msg);
        break;

      default:
        break;
    }
  };

  // note: temp removed alert as the durations of the uploaded ugc is unknown
  // const missingClips = useMemo(() => {
  //   if (face.videos == null) return [];

  //   const existingClips = face.videos.map((video) => video.parameters?.length);
  //   return isEnoughClips(existingClips, event.wildcards);
  // }, [event.wildcards, face.videos]);

  const handleUpload = (file, fileList) => {
    setVideoFiles([...videoFiles, ...fileList]);
  };

  const requiredShots = useMemo(() => {
    if (!isMissingVideo) return null;
    return reqShots.split(',');
  }, [isMissingVideo, reqShots]);

  const { ugcInfoPoints, modalData, tipsList } = getVideoText(
    event,
    face,
    isProcessing,
    isDetailsProvided,
    onHideConfirmModal,
    requiredShots,
  );

  const containerClass = 'flex flex-wrap gap-4';

  return (
    <Form className="w-full flex flex-col gap-4" onSubmit={onSubmit} noValidate>
      {!isEmpty(face.videos) && (
        <React.Fragment>
          <div className="flex flex-col gap-2">
            <h5>Uploaded videos ({face.videos.length})</h5>

            <div className={containerClass}>
              {face.videos.map((video) => (
                <VideoBlock video={video} onClick={setPreviewVideo} />
              ))}
            </div>
          </div>

          {/* note: temp removed alert as the durations of the uploaded ugc is unknown */}
          {/* {!isEmpty(missingClips) && (
                <Alert
                  alertData={{
                    variant: 'warning',
                    text:
                      "You're still missing " +
                      getWildcardTextMaximum(missingClips, 'video') +
                      '.',
                  }}
                />
              )} */}

          <hr />

          <h5>Upload more videos</h5>
        </React.Fragment>
      )}

      <DraggerSimplified
        type="video"
        heading={`Upload videos (${
          isMissingVideo ? '1 and more' : event.wildcards.length
        })`}
        isMultiple={true}
        onDrop={handleUpload}
      />

      <UploadInfoPoints points={ugcInfoPoints} />

      {!isEmpty(videoFiles) && (
        <div className="flex flex-col gap-2">
          <h6>Your videos ({videoFiles.length})</h6>

          <div className={containerClass}>
            {videoFiles.map((video) => (
              <VideoBlock video={video} />
            ))}
          </div>
        </div>
      )}

      <SubmitBtn
        title="Upload"
        isProcessing={isProcessing}
        isDisabled={isEmpty(videoFiles)}
        className="w-full"
      />

      <VideoPreviewModal
        show={!!previewVideo}
        onHide={() => setPreviewVideo(null)}
        video={previewVideo}
      />

      <ConfirmModal
        show={showConfirmModal}
        onHide={onHideConfirmModal}
        isProcessing={isProcessing}
        heading={modalData.heading}
        subheading={modalData.subheading}
        tipsList={tipsList}
        btn={modalData.btn}
      />

      <DetailsModal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        event={event}
        face={face}
        isProcessing={isProcessing}
        handleDetailsSteps={handleDetailsSteps}
      />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
      />
    </Form>
  );
};

export default VideoStage;
