import React, { useState } from 'react';
import { Upload } from 'antd';
import parse from 'html-react-parser';
import { Modal } from 'react-bootstrap';
import { isMobile, isTablet } from 'react-device-detect';
import { PiCloudArrowUp } from 'react-icons/pi';
import { isEmpty } from '../../../Utils';
import {
  trackUploadFileSuccess,
  trackUploadBatchSuccess,
  trackUploadError,
} from '../../../analytics';

import { Button } from '../../../components';

import { SELECTS_UPLOAD_LIMIT, MAX_FILE_SIZE_MB } from '../../../constants';

import { uploadFile } from '../../../services/uploadFileService';

import { useEventStore } from '../../../stores/event';

import ShotModal from './shotModal';
import VideoModeModal from './videoModeModal';

const FileDropArea = (props) => {
  const {
    type,
    onDrop,
    onProgress,
    onComplete,
    onError,
    setFilesNum,
    uploadRef,
    isDemo,
  } = props;
  const currentEvent = useEventStore((state) => state.currentEvent);

  const [videoFiles, setVideoFiles] = useState([]);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [noteModalContent, setNoteModalContent] = useState('');
  const [showModeModal, setShowModeModal] = useState(false);
  const [showShotModal, setShowShotModal] = useState(false);
  const eventId = useEventStore((state) => state.eventId);

  // TODO: Move this into a store when the backend is able to store config
  let videoModeSaved = JSON.parse(localStorage.getItem('videoModeSaved')) ?? {};
  let hlReelShotSaved =
    JSON.parse(localStorage.getItem('hlReelShotSaved')) ?? false;

  if (typeof videoModeSaved === 'object')
    videoModeSaved = videoModeSaved[eventId];

  const fileKind = type === 'hl-reel' ? 'video-template' : 'video-footage';
  const analyticsPage = type.replace(/-/g, '_');

  // uploads each video in an array
  const handleUpload = (newVideoFiles) => {
    // use either custom video array or the one saved in state
    const videos = newVideoFiles || videoFiles;

    // custom actions from the parent
    onDrop && onDrop(videos);

    if (!isEmpty(videos)) {
      let totalVideoDuration = 0;
      let oneTimeGA4Sent = false;

      videos.forEach((video) => {
        // Create a video element to get the duration
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';

        videoElement.onloadedmetadata = function () {
          window.URL.revokeObjectURL(videoElement.src);
          const duration = videoElement.duration;
          totalVideoDuration = totalVideoDuration + duration;

          // Proceed with file upload after getting the duration
          uploadFile({ file: video, eventId, fileKind, onProgress })
            // when upload is complete – run actions from the parent
            .then(() => {
              // track each individual video upload with its duration
              trackUploadFileSuccess(analyticsPage, eventId, duration);

              // Clips custom GA4 event for total duration - One Time
              if (type === 'clips' && !oneTimeGA4Sent) {
                trackUploadBatchSuccess('clips', eventId, totalVideoDuration);
                oneTimeGA4Sent = true;
              }

              onComplete && onComplete();
            })
            // if error happens – run actions from the parent
            .catch(() => {
              trackUploadError(analyticsPage, video.name);

              onError && onError(video);
            });
        };

        videoElement.onerror = function () {
          // Handle error if the video cannot be loaded
          onError && onError(video);
        };

        // Set the video src to the selected video file
        videoElement.src = URL.createObjectURL(video);
      });
    }

    // clear video files after uploaded
    setVideoFiles([]);
  };

  const beforeUpload = (file, fileList) => {
    setFilesNum && setFilesNum(fileList.length);

    // check if number of files exceeds the maximum allowed number
    if (type === 'clips' && fileList.length > SELECTS_UPLOAD_LIMIT) {
      console.error('Maximum file count exceeded');
      setShowNoteModal(true);
      setNoteModalContent(
        `Maximum number of files exceeded! To optimise the uploading process, please only upload <b>${SELECTS_UPLOAD_LIMIT} files</b> at a time.`,
      );
      return false;
    }

    // check if file size exceeds the maximum allowed size
    if (type === 'clips' && file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
      console.error('Maximum file size exceeded');
      setShowNoteModal(true);
      setNoteModalContent(
        `File size exceeds the maximum limit of <b>${MAX_FILE_SIZE_MB} MB</b>! To optimise the uploading process, Please upload a smaller file.`,
      );
      return false;
    }

    // if video mode is NOT selected or the reminder is NOT saved – save file list in state and show mode modal
    if (!currentEvent.videoMode || !videoModeSaved) {
      setVideoFiles(fileList);
      setShowModeModal(true);
    }

    // else if type is highlight reel and the reminder is NOT saved – save file list in state and show mode modal
    else if (type === 'hl-reel' && !hlReelShotSaved) {
      setVideoFiles(fileList);
      setShowShotModal(true);
    }

    // else – handle upload one by one
    else handleUpload([file]);

    // file is never uploading via customRequest
    return false;
  };

  const onHideModeModal = () => {
    setShowModeModal(false);
  };

  let draggerProps = {
    name: 'file',
    accept: 'video/*',
    showUploadList: false,
  };

  let typeText = type;

  if (type === 'hl-reel') {
    draggerProps = { ...draggerProps, maxCount: 1, multiple: false };
    typeText = 'highlight reel';
  } else if (type === 'clips') {
    draggerProps = { ...draggerProps, multiple: true };
  }

  return (
    <div className="flex flex-col gap-2">
      {isDemo && (
        <div className="italic mt-2">
          You cannot upload your own files here, but you'll be able to in all
          live projects.
        </div>
      )}

      <Upload.Dragger
        {...draggerProps}
        className="w-full rounded-md shadow-xl transition-all hover:bg-grey-100"
        beforeUpload={(file, fileList) => beforeUpload(file, fileList)}
        ref={uploadRef}
        disabled={isDemo}
      >
        <div className="flex flex-col items-center justify-center gap-2 px-4">
          <PiCloudArrowUp size="2.5rem" />

          {isMobile || isTablet ? (
            <div className="text-md">
              <b>Click here</b> to upload {typeText}
            </div>
          ) : (
            <div className="text-md">
              <b>Drag & drop</b> to upload {typeText}
              <br />
              or <b>click here to browse</b>
            </div>
          )}
        </div>
      </Upload.Dragger>

      <Modal
        show={showNoteModal}
        onHide={() => setShowNoteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Note</Modal.Title>
        </Modal.Header>

        <Modal.Body className="flex flex-col gap-4 text-center text-lg">
          <div>{parse(noteModalContent)}</div>

          <Button className="w-full" onClick={() => setShowNoteModal(false)}>
            OK
          </Button>
        </Modal.Body>
      </Modal>

      <VideoModeModal
        show={showModeModal}
        onHide={onHideModeModal}
        handleUpload={() => type !== 'hl-reel' && handleUpload()}
        rejectUpload={() => setVideoFiles([])}
        type={type}
        onShowShot={() => setShowShotModal(true)}
      />

      <ShotModal
        show={showShotModal}
        onHide={() => setShowShotModal(false)}
        mode={currentEvent?.videoMode}
        handleUpload={handleUpload}
        rejectUpload={() => setVideoFiles([])}
      />
    </div>
  );
};

export default FileDropArea;
