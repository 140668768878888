import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import PopoverTrigger from '../../../components/popoverTrigger';
import { DEMO_ID } from '../../../constants';

const HeadingWithBadge = (props) => {
  const { children } = props;

  const eventId = useParams()?.eventId;

  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  if (!isDemo) return children;

  return (
    <div className="flex items-center gap-2 flex-wrap">
      {children}

      <PopoverTrigger
        content={
          <div className="w-auto h-min p-2 bg-grey-100 rounded-full">
            <div className="text-sm font-bold uppercase leading-none bg-gradient-purple-light bg-clip-text text-transparent">
              Sample
            </div>
          </div>
        }
        placement="bottom"
        popoverBody="This is a Sample Project, where you can try out CrowdClip® without providing any assets."
      />
    </div>
  );
};

export default HeadingWithBadge;
